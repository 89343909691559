export enum EntityType {
  Article = 'article',
  CaseStudy = 'casestudy',
  Company = 'company',
  JobAd = 'jobad',
  MarketShowcase = 'marketshowcase',
  Project = 'project',
  ProjectOutline = 'projectoutline',
  ProjectPayment = 'projectpayment',
  ProjectPitch = 'projectpitch',
  ProjectShowcase = 'projectshowcase',
  User = 'user',
}

export enum EnumUserInvitationStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Revoked = 'revoked',
}

export const mapUserInvitationStatus = new Map([
  [EnumUserInvitationStatus.Pending, 'Pending'],
  [EnumUserInvitationStatus.Accepted, 'Accepted'],
  [EnumUserInvitationStatus.Revoked, 'Revoked'],
]);

export enum EnumArticleType {
  Analysis = 'analysis',
  Blog = 'blog',
  Ebook = 'ebook',
  Editorial = 'editorial',
  Guide = 'guide',
  Infographic = 'infographic',
  Interview = 'interview',
  News = 'news',
  Presentation = 'presentation',
  PressRelease = 'press_release',
  Research = 'research',
  Showcase = 'showcase',
  Tutorial = 'tutorial',
}

export enum EnumClaimCompany {
  ApprovedPrivate = 'approved_private',
  ApprovedPublic = 'approved_public',
  Bar = 'bar',
  Baz = 'baz',
  Buyer = 'buyer',
  Foo = 'foo',
  Foobar = 'foobar',
  Seller = 'seller',
  SellerProduct = 'seller_product',
  SellerService = 'seller_service',
}

export enum EnumClaimCompanyUser {
  Bar = 'bar',
  Baz = 'baz',
  CompanyAdmin = 'company_admin',
  EmailVerified = 'email_verified',
  Foo = 'foo',
  Foobar = 'foobar',
  PhoneVerified = 'phone_verified',
}

export enum EnumClaimUser {
  Bar = 'bar',
  EmailVerified = 'email_verified',
  Foo = 'foo',
  Foobar = 'foobar',
  IsSuperuser = 'is_superuser',
  LinkedinVerified = 'linkedin_verified',
  PhoneVerified = 'phone_verified',
  UserVerified = 'user_verified',
}

export enum EnumCompanyLocationType {
  BranchOffice = 'branch_office',
  Headquarters = 'headquarters',
  RegionalHeadquarters = 'regional_headquarters',
  Subsidiary = 'subsidiary',
}

export enum EnumCompanySize {
  Bt_2_10 = 'bt_2_10',
  Bt_11_50 = 'bt_11_50',
  Bt_51_200 = 'bt_51_200',
  Bt_201_500 = 'bt_201_500',
  Bt_501_1000 = 'bt_501_1000',
  Bt_1001_5000 = 'bt_1001_5000',
  Bt_5001_10000 = 'bt_5001_10000',
  Eq_1 = 'eq_1',
  Gte_10001 = 'gte_10001',
}

export const mapCompanySize = new Map([
  [EnumCompanySize.Eq_1, '1 employee'],
  [EnumCompanySize.Bt_2_10, '2-10 employees'],
  [EnumCompanySize.Bt_11_50, '11-50 employees'],
  [EnumCompanySize.Bt_51_200, '51-200 employees'],
  [EnumCompanySize.Bt_201_500, '201-500 employees'],
  [EnumCompanySize.Bt_501_1000, '501-1000 employees'],
  [EnumCompanySize.Bt_1001_5000, '1001-5000 employees'],
  [EnumCompanySize.Bt_5001_10000, '5001-10000 employees'],
  [EnumCompanySize.Gte_10001, '10001+ employees'],
]);

export enum CompanyApprovalStatus {
  Approved = 'approved',
  Rejected = 'rejected',
  Pending = 'pending',
  Unconfirmed = 'unconfirmed',
}

export const mapCompanyApprovalStatus = new Map([
  [CompanyApprovalStatus.Approved, 'Approved'],
  [CompanyApprovalStatus.Rejected, 'Rejected'],
  [CompanyApprovalStatus.Pending, 'Pending'],
  [CompanyApprovalStatus.Unconfirmed, 'Unconfirmed'],
]);

export enum EnumPitchViabilityErrors {
  CaseStudyMissing = 'case_study_missing',
  CaseStudyReviewMissing = 'case_study_review_missing',
  ProfileIncomplete = 'profile_incomplete',
  PitchExists = 'pitch_exists',
}

export enum EnumCountry {
  Ab = 'ab',
  Ad = 'ad',
  Ae = 'ae',
  Af = 'af',
  Ag = 'ag',
  Ai = 'ai',
  Al = 'al',
  Am = 'am',
  Ao = 'ao',
  Aq = 'aq',
  Ar = 'ar',
  As = 'as',
  At = 'at',
  Au = 'au',
  Aw = 'aw',
  Ax = 'ax',
  Az = 'az',
  Ba = 'ba',
  Bb = 'bb',
  Bd = 'bd',
  Be = 'be',
  Bf = 'bf',
  Bg = 'bg',
  Bh = 'bh',
  Bi = 'bi',
  Bj = 'bj',
  Bl = 'bl',
  Bm = 'bm',
  Bn = 'bn',
  Bo = 'bo',
  Bq = 'bq',
  Br = 'br',
  Bs = 'bs',
  Bt = 'bt',
  Bv = 'bv',
  Bw = 'bw',
  By = 'by',
  Bz = 'bz',
  Ca = 'ca',
  Cc = 'cc',
  Cd = 'cd',
  Cf = 'cf',
  Cg = 'cg',
  Ch = 'ch',
  Ci = 'ci',
  Ck = 'ck',
  Cl = 'cl',
  Cm = 'cm',
  Cn = 'cn',
  Co = 'co',
  Cr = 'cr',
  Cu = 'cu',
  Cv = 'cv',
  Cw = 'cw',
  Cx = 'cx',
  Cy = 'cy',
  Cz = 'cz',
  De = 'de',
  Dj = 'dj',
  Dk = 'dk',
  Dm = 'dm',
  Do = 'do',
  Dz = 'dz',
  Ec = 'ec',
  Ee = 'ee',
  Eg = 'eg',
  Eh = 'eh',
  Er = 'er',
  Es = 'es',
  Et = 'et',
  Fi = 'fi',
  Fj = 'fj',
  Fk = 'fk',
  Fm = 'fm',
  Fo = 'fo',
  Fr = 'fr',
  Ga = 'ga',
  Gb = 'gb',
  Gd = 'gd',
  Ge = 'ge',
  Gf = 'gf',
  Gg = 'gg',
  Gh = 'gh',
  Gi = 'gi',
  Gl = 'gl',
  Gm = 'gm',
  Gn = 'gn',
  Gp = 'gp',
  Gq = 'gq',
  Gr = 'gr',
  Gs = 'gs',
  Gt = 'gt',
  Gu = 'gu',
  Gw = 'gw',
  Gy = 'gy',
  Hk = 'hk',
  Hm = 'hm',
  Hn = 'hn',
  Hr = 'hr',
  Ht = 'ht',
  Hu = 'hu',
  Id = 'id',
  Ie = 'ie',
  Il = 'il',
  Im = 'im',
  In = 'in',
  Io = 'io',
  Iq = 'iq',
  Ir = 'ir',
  Is = 'is',
  It = 'it',
  Je = 'je',
  Jm = 'jm',
  Jo = 'jo',
  Jp = 'jp',
  Ke = 'ke',
  Kg = 'kg',
  Kh = 'kh',
  Ki = 'ki',
  Km = 'km',
  Kn = 'kn',
  Kp = 'kp',
  Kr = 'kr',
  Kw = 'kw',
  Ky = 'ky',
  Kz = 'kz',
  La = 'la',
  Lb = 'lb',
  Lc = 'lc',
  Li = 'li',
  Lk = 'lk',
  Lr = 'lr',
  Ls = 'ls',
  Lt = 'lt',
  Lu = 'lu',
  Lv = 'lv',
  Ly = 'ly',
  Ma = 'ma',
  Mc = 'mc',
  Md = 'md',
  Me = 'me',
  Mf = 'mf',
  Mg = 'mg',
  Mh = 'mh',
  Mk = 'mk',
  Ml = 'ml',
  Mm = 'mm',
  Mn = 'mn',
  Mo = 'mo',
  Mp = 'mp',
  Mq = 'mq',
  Mr = 'mr',
  Ms = 'ms',
  Mt = 'mt',
  Mu = 'mu',
  Mv = 'mv',
  Mw = 'mw',
  Mx = 'mx',
  My = 'my',
  Mz = 'mz',
  Na = 'na',
  Nc = 'nc',
  Ne = 'ne',
  Nf = 'nf',
  Ng = 'ng',
  Ni = 'ni',
  Nl = 'nl',
  No = 'no',
  Np = 'np',
  Nr = 'nr',
  Nu = 'nu',
  Nz = 'nz',
  Om = 'om',
  Pa = 'pa',
  Pe = 'pe',
  Pf = 'pf',
  Pg = 'pg',
  Ph = 'ph',
  Pk = 'pk',
  Pl = 'pl',
  Pm = 'pm',
  Pn = 'pn',
  Pr = 'pr',
  Ps = 'ps',
  Pt = 'pt',
  Pw = 'pw',
  Py = 'py',
  Qa = 'qa',
  Re = 're',
  Ro = 'ro',
  Rs = 'rs',
  Ru = 'ru',
  Rw = 'rw',
  Sa = 'sa',
  Sb = 'sb',
  Sc = 'sc',
  Sd = 'sd',
  Se = 'se',
  Sg = 'sg',
  Sh = 'sh',
  Si = 'si',
  Sj = 'sj',
  Sk = 'sk',
  Sl = 'sl',
  Sm = 'sm',
  Sn = 'sn',
  So = 'so',
  Sr = 'sr',
  Ss = 'ss',
  St = 'st',
  Sv = 'sv',
  Sx = 'sx',
  Sy = 'sy',
  Sz = 'sz',
  Tc = 'tc',
  Td = 'td',
  Tf = 'tf',
  Tg = 'tg',
  Th = 'th',
  Tj = 'tj',
  Tk = 'tk',
  Tl = 'tl',
  Tm = 'tm',
  Tn = 'tn',
  To = 'to',
  Tr = 'tr',
  Tt = 'tt',
  Tv = 'tv',
  Tw = 'tw',
  Tz = 'tz',
  Ua = 'ua',
  Ug = 'ug',
  Um = 'um',
  Us = 'us',
  Uy = 'uy',
  Uz = 'uz',
  Va = 'va',
  Vc = 'vc',
  Ve = 've',
  Vg = 'vg',
  Vi = 'vi',
  Vn = 'vn',
  Vu = 'vu',
  Wf = 'wf',
  Ws = 'ws',
  Xk = 'xk',
  Ye = 'ye',
  Yt = 'yt',
  Za = 'za',
  Zm = 'zm',
  Zw = 'zw',
}

export const mapCountry = new Map([
  [EnumCountry.Ab, 'Abkhazia'],
  [EnumCountry.Ad, 'Andorra'],
  [EnumCountry.Ae, 'United Arab Emirates'],
  [EnumCountry.Af, 'Afghanistan'],
  [EnumCountry.Ag, 'Antigua and Barbuda'],
  [EnumCountry.Ai, 'Anguilla'],
  [EnumCountry.Al, 'Albania'],
  [EnumCountry.Am, 'Armenia'],
  [EnumCountry.Ao, 'Angola'],
  [EnumCountry.Aq, 'Antarctica'],
  [EnumCountry.Ar, 'Argentina'],
  [EnumCountry.As, 'American Samoa'],
  [EnumCountry.At, 'Austria'],
  [EnumCountry.Au, 'Australia'],
  [EnumCountry.Aw, 'Aruba'],
  [EnumCountry.Ax, 'Aland Islands'],
  [EnumCountry.Az, 'Azerbaijan'],
  [EnumCountry.Ba, 'Bosnia and Herzegovina'],
  [EnumCountry.Bb, 'Barbados'],
  [EnumCountry.Bd, 'Bangladesh'],
  [EnumCountry.Be, 'Belgium'],
  [EnumCountry.Bf, 'Burkina Faso'],
  [EnumCountry.Bg, 'Bulgaria'],
  [EnumCountry.Bh, 'Bahrain'],
  [EnumCountry.Bi, 'Burundi'],
  [EnumCountry.Bj, 'Benin'],
  [EnumCountry.Bl, 'Saint-Barthélemy'],
  [EnumCountry.Bm, 'Bermuda'],
  [EnumCountry.Bn, 'Brunei Darussalam'],
  [EnumCountry.Bo, 'Bolivia'],
  [EnumCountry.Bq, 'Caribbean Netherlands'],
  [EnumCountry.Br, 'Brazil'],
  [EnumCountry.Bs, 'Bahamas'],
  [EnumCountry.Bt, 'Bhutan'],
  [EnumCountry.Bv, 'Bouvet Island'],
  [EnumCountry.Bw, 'Botswana'],
  [EnumCountry.By, 'Belarus'],
  [EnumCountry.Bz, 'Belize'],
  [EnumCountry.Ca, 'Canada'],
  [EnumCountry.Cc, 'Cocos (Keeling) Islands'],
  [EnumCountry.Cd, 'Democratic Republic of the Congo (Congo-Kinshasa, former Zaire)'],
  [EnumCountry.Cf, 'Centrafrican Republic'],
  [EnumCountry.Cg, 'Republic of the Congo (Congo-Brazzaville)'],
  [EnumCountry.Ch, 'Switzerland'],
  [EnumCountry.Ci, "Côte d'Ivoire"],
  [EnumCountry.Ck, 'Cook Islands'],
  [EnumCountry.Cl, 'Chile'],
  [EnumCountry.Cm, 'Cameroon'],
  [EnumCountry.Cn, 'China'],
  [EnumCountry.Co, 'Colombia'],
  [EnumCountry.Cr, 'Costa Rica'],
  [EnumCountry.Cu, 'Cuba'],
  [EnumCountry.Cv, 'Cabo Verde'],
  [EnumCountry.Cw, 'Curaçao'],
  [EnumCountry.Cx, 'Christmas Island'],
  [EnumCountry.Cy, 'Cyprus'],
  [EnumCountry.Cz, 'Czech Republic'],
  [EnumCountry.De, 'Germany'],
  [EnumCountry.Dj, 'Djibouti'],
  [EnumCountry.Dk, 'Denmark'],
  [EnumCountry.Dm, 'Dominica'],
  [EnumCountry.Do, 'Dominican Republic'],
  [EnumCountry.Dz, 'Algeria'],
  [EnumCountry.Ec, 'Ecuador'],
  [EnumCountry.Ee, 'Estonia'],
  [EnumCountry.Eg, 'Egypt'],
  [EnumCountry.Eh, 'Western Sahara'],
  [EnumCountry.Er, 'Eritrea'],
  [EnumCountry.Es, 'Spain'],
  [EnumCountry.Et, 'Ethiopia'],
  [EnumCountry.Fi, 'Finland'],
  [EnumCountry.Fj, 'Fiji'],
  [EnumCountry.Fk, 'Falkland Islands'],
  [EnumCountry.Fm, 'Micronesia (Federated States of)'],
  [EnumCountry.Fo, 'Faroe Islands'],
  [EnumCountry.Fr, 'France'],
  [EnumCountry.Ga, 'Gabon'],
  [EnumCountry.Gb, 'United Kingdom'],
  [EnumCountry.Gd, 'Grenada'],
  [EnumCountry.Ge, 'Georgia'],
  [EnumCountry.Gf, 'French Guiana'],
  [EnumCountry.Gg, 'Guernsey'],
  [EnumCountry.Gh, 'Ghana'],
  [EnumCountry.Gi, 'Gibraltar'],
  [EnumCountry.Gl, 'Greenland'],
  [EnumCountry.Gm, 'The Gambia'],
  [EnumCountry.Gn, 'Guinea'],
  [EnumCountry.Gp, 'Guadeloupe'],
  [EnumCountry.Gq, 'Equatorial Guinea'],
  [EnumCountry.Gr, 'Greece'],
  [EnumCountry.Gs, 'South Georgia and the South Sandwich Islands'],
  [EnumCountry.Gt, 'Guatemala'],
  [EnumCountry.Gu, 'Guam'],
  [EnumCountry.Gw, 'Guinea Bissau'],
  [EnumCountry.Gy, 'Guyana'],
  [EnumCountry.Hk, 'Hong Kong (SAR of China)'],
  [EnumCountry.Hm, 'Heard Island and McDonald Islands'],
  [EnumCountry.Hn, 'Honduras'],
  [EnumCountry.Hr, 'Croatia'],
  [EnumCountry.Ht, 'Haiti'],
  [EnumCountry.Hu, 'Hungary'],
  [EnumCountry.Id, 'Indonesia'],
  [EnumCountry.Ie, 'Ireland'],
  [EnumCountry.Il, 'Israel'],
  [EnumCountry.Im, 'Isle of Man'],
  [EnumCountry.In, 'India'],
  [EnumCountry.Io, 'British Indian Ocean Territory'],
  [EnumCountry.Iq, 'Iraq'],
  [EnumCountry.Ir, 'Iran'],
  [EnumCountry.Is, 'Iceland'],
  [EnumCountry.It, 'Italy'],
  [EnumCountry.Je, 'Jersey'],
  [EnumCountry.Jm, 'Jamaica'],
  [EnumCountry.Jo, 'Jordan'],
  [EnumCountry.Jp, 'Japan'],
  [EnumCountry.Ke, 'Kenya'],
  [EnumCountry.Kg, 'Kyrgyzstan'],
  [EnumCountry.Kh, 'Cambodia'],
  [EnumCountry.Ki, 'Kiribati'],
  [EnumCountry.Km, 'Comores'],
  [EnumCountry.Kn, 'Saint Kitts and Nevis'],
  [EnumCountry.Kp, 'North Korea'],
  [EnumCountry.Kr, 'South Korea'],
  [EnumCountry.Kw, 'Kuwait'],
  [EnumCountry.Ky, 'Cayman Islands'],
  [EnumCountry.Kz, 'Kazakhstan'],
  [EnumCountry.La, 'Laos'],
  [EnumCountry.Lb, 'Lebanon'],
  [EnumCountry.Lc, 'Saint Lucia'],
  [EnumCountry.Li, 'Liechtenstein'],
  [EnumCountry.Lk, 'Sri Lanka'],
  [EnumCountry.Lr, 'Liberia'],
  [EnumCountry.Ls, 'Lesotho'],
  [EnumCountry.Lt, 'Lithuania'],
  [EnumCountry.Lu, 'Luxembourg'],
  [EnumCountry.Lv, 'Latvia'],
  [EnumCountry.Ly, 'Libya'],
  [EnumCountry.Ma, 'Morocco'],
  [EnumCountry.Mc, 'Monaco'],
  [EnumCountry.Md, 'Moldova'],
  [EnumCountry.Me, 'Montenegro'],
  [EnumCountry.Mf, 'Saint Martin (French part)'],
  [EnumCountry.Mg, 'Madagascar'],
  [EnumCountry.Mh, 'Marshall Islands'],
  [EnumCountry.Mk, 'North Macedonia'],
  [EnumCountry.Ml, 'Mali'],
  [EnumCountry.Mm, 'Myanmar'],
  [EnumCountry.Mn, 'Mongolia'],
  [EnumCountry.Mo, 'Macao (SAR of China)'],
  [EnumCountry.Mp, 'Northern Mariana Islands'],
  [EnumCountry.Mq, 'Martinique'],
  [EnumCountry.Mr, 'Mauritania'],
  [EnumCountry.Ms, 'Montserrat'],
  [EnumCountry.Mt, 'Malta'],
  [EnumCountry.Mu, 'Mauritius'],
  [EnumCountry.Mv, 'Maldives'],
  [EnumCountry.Mw, 'Malawi'],
  [EnumCountry.Mx, 'Mexico'],
  [EnumCountry.My, 'Malaysia'],
  [EnumCountry.Mz, 'Mozambique'],
  [EnumCountry.Na, 'Namibia'],
  [EnumCountry.Nc, 'New Caledonia'],
  [EnumCountry.Ne, 'Niger'],
  [EnumCountry.Nf, 'Norfolk Island'],
  [EnumCountry.Ng, 'Nigeria'],
  [EnumCountry.Ni, 'Nicaragua'],
  [EnumCountry.Nl, 'The Netherlands'],
  [EnumCountry.No, 'Norway'],
  [EnumCountry.Np, 'Nepal'],
  [EnumCountry.Nr, 'Nauru'],
  [EnumCountry.Nu, 'Niue'],
  [EnumCountry.Nz, 'New Zealand'],
  [EnumCountry.Om, 'Oman'],
  [EnumCountry.Pa, 'Panama'],
  [EnumCountry.Pe, 'Peru'],
  [EnumCountry.Pf, 'French Polynesia'],
  [EnumCountry.Pg, 'Papua New Guinea'],
  [EnumCountry.Ph, 'Philippines'],
  [EnumCountry.Pk, 'Pakistan'],
  [EnumCountry.Pl, 'Poland'],
  [EnumCountry.Pm, 'Saint Pierre and Miquelon'],
  [EnumCountry.Pn, 'Pitcairn'],
  [EnumCountry.Pr, 'Puerto Rico'],
  [EnumCountry.Ps, 'Palestinian Territory'],
  [EnumCountry.Pt, 'Portugal'],
  [EnumCountry.Pw, 'Palau'],
  [EnumCountry.Py, 'Paraguay'],
  [EnumCountry.Qa, 'Qatar'],
  [EnumCountry.Re, 'Reunion'],
  [EnumCountry.Ro, 'Romania'],
  [EnumCountry.Rs, 'Serbia'],
  [EnumCountry.Ru, 'Russia'],
  [EnumCountry.Rw, 'Rwanda'],
  [EnumCountry.Sa, 'Saudi Arabia'],
  [EnumCountry.Sb, 'Solomon Islands'],
  [EnumCountry.Sc, 'Seychelles'],
  [EnumCountry.Sd, 'Sudan'],
  [EnumCountry.Se, 'Sweden'],
  [EnumCountry.Sg, 'Singapore'],
  [EnumCountry.Sh, 'Saint Helena'],
  [EnumCountry.Si, 'Slovenia'],
  [EnumCountry.Sj, 'Svalbard and Jan Mayen'],
  [EnumCountry.Sk, 'Slovakia'],
  [EnumCountry.Sl, 'Sierra Leone'],
  [EnumCountry.Sm, 'San Marino'],
  [EnumCountry.Sn, 'Sénégal'],
  [EnumCountry.So, 'Somalia'],
  [EnumCountry.Sr, 'Suriname'],
  [EnumCountry.Ss, 'São Tomé and Príncipe'],
  [EnumCountry.St, 'South Sudan'],
  [EnumCountry.Sv, 'El Salvador'],
  [EnumCountry.Sx, 'Saint Martin (Dutch part)'],
  [EnumCountry.Sy, 'Syria'],
  [EnumCountry.Sz, 'eSwatini'],
  [EnumCountry.Tc, 'Turks and Caicos Islands'],
  [EnumCountry.Td, 'Chad'],
  [EnumCountry.Tf, 'French Southern and Antarctic Lands'],
  [EnumCountry.Tg, 'Togo'],
  [EnumCountry.Th, 'Thailand'],
  [EnumCountry.Tj, 'Tajikistan'],
  [EnumCountry.Tk, 'Tokelau'],
  [EnumCountry.Tl, 'Timor-Leste'],
  [EnumCountry.Tm, 'Turkmenistan'],
  [EnumCountry.Tn, 'Tunisia'],
  [EnumCountry.To, 'Tonga'],
  [EnumCountry.Tr, 'Turkey'],
  [EnumCountry.Tt, 'Trinidad and Tobago'],
  [EnumCountry.Tv, 'Tuvalu'],
  [EnumCountry.Tw, 'Taiwan'],
  [EnumCountry.Tz, 'Tanzania'],
  [EnumCountry.Ua, 'Ukraine'],
  [EnumCountry.Ug, 'Uganda'],
  [EnumCountry.Um, 'United States Minor Outlying Islands'],
  [EnumCountry.Us, 'United States of America'],
  [EnumCountry.Uy, 'Uruguay'],
  [EnumCountry.Uz, 'Uzbekistan'],
  [EnumCountry.Va, 'City of the Vatican'],
  [EnumCountry.Vc, 'Saint Vincent and the Grenadines'],
  [EnumCountry.Ve, 'Venezuela'],
  [EnumCountry.Vg, 'British Virgin Islands'],
  [EnumCountry.Vi, 'United States Virgin Islands'],
  [EnumCountry.Vn, 'Vietnam'],
  [EnumCountry.Vu, 'Vanuatu'],
  [EnumCountry.Wf, 'Wallis and Futuna'],
  [EnumCountry.Ws, 'Samoa'],
  [EnumCountry.Xk, 'Kosovo'],
  [EnumCountry.Ye, 'Yemen'],
  [EnumCountry.Yt, 'Mayotte'],
  [EnumCountry.Za, 'South Africa'],
  [EnumCountry.Zm, 'Zambia'],
  [EnumCountry.Zw, 'Zimbabwe'],
]);

export enum EnumCurrency {
  Eur = 'eur',
  Btc = 'btc',
  Eth = 'eth',
  Hrk = 'hrk',
  Inr = 'inr',
  Usd = 'usd',
}

export const mapCurrency = new Map([
  [EnumCurrency.Eur, 'EUR'],
  [EnumCurrency.Usd, 'USD'],
  [EnumCurrency.Eur, 'EUR'],
]);

export enum EnumIndustry {
  Accounting = 'accounting',
  AirlinesAndAviation = 'airlines_and_aviation',
  AlternativeDisputeResolution = 'alternative_dispute_resolution',
  AlternativeMedicine = 'alternative_medicine',
  Animation = 'animation',
  ApparelAndFashion = 'apparel_and_fashion',
  ArchitectureAndPlanning = 'architecture_and_planning',
  ArtsAndCrafts = 'arts_and_crafts',
  Automotive = 'automotive',
  AviationAndAerospace = 'aviation_and_aerospace',
  Banking = 'banking',
  Biotechnology = 'biotechnology',
  BroadcastMedia = 'broadcast_media',
  BuildingMaterials = 'building_materials',
  BusinessSuppliesAndEquipment = 'business_supplies_and_equipment',
  CapitalMarkets = 'capital_markets',
  Chemicals = 'chemicals',
  CivicAndSocialOrganization = 'civic_and_social_organization',
  CivilEngineering = 'civil_engineering',
  CommercialRealEstate = 'commercial_real_estate',
  ComputerGames = 'computer_games',
  ComputerHardware = 'computer_hardware',
  ComputerNetworking = 'computer_networking',
  ComputerSoftware = 'computer_software',
  ComputerAndNetworkSecurity = 'computer_and_network_security',
  Construction = 'construction',
  ConsumerElectronics = 'consumer_electronics',
  ConsumerGoods = 'consumer_goods',
  ConsumerServices = 'consumer_services',
  Cosmetics = 'cosmetics',
  Dairy = 'dairy',
  DefenseAndSpace = 'defense_and_space',
  Design = 'design',
  EducationManagement = 'education_management',
  Elearning = 'elearning',
  ElectricalOrElectronicManufacturing = 'electrical_or_electronic_manufacturing',
  Energy = 'energy',
  Entertainment = 'entertainment',
  EnvironmentalServices = 'environmental_services',
  EventsServices = 'events_services',
  ExecutiveOffice = 'executive_office',
  FacilitiesServices = 'facilities_services',
  Farming = 'farming',
  FastMovingConsumerGoods = 'fast_moving_consumer_goods',
  FinancialServices = 'financial_services',
  FineArt = 'fine_art',
  Fishery = 'fishery',
  FoodProduction = 'food_production',
  FoodAndBeverages = 'food_and_beverages',
  Fundraising = 'fundraising',
  Furniture = 'furniture',
  GamblingAndCasinos = 'gambling_and_casinos',
  GlassAndCeramicsAndConcrete = 'glass_and_ceramics_and_concrete',
  GovernmentAdministration = 'government_administration',
  GovernmentRelations = 'government_relations',
  GraphicDesign = 'graphic_design',
  HealthAndWellnessAndFitness = 'health_and_wellness_and_fitness',
  HigherEducation = 'higher_education',
  HospitalAndHealthCare = 'hospital_and_health_care',
  Hospitality = 'hospitality',
  HumanResources = 'human_resources',
  ImportAndExport = 'import_and_export',
  IndividualAndFamilyServices = 'individual_and_family_services',
  IndustrialAutomation = 'industrial_automation',
  InformationServices = 'information_services',
  InformationTechnology = 'information_technology',
  Insurance = 'insurance',
  InternationalAffairs = 'international_affairs',
  InternationalTradeAndDevelopment = 'international_trade_and_development',
  Internet = 'internet',
  InvestmentBanking = 'investment_banking',
  InvestmentManagement = 'investment_management',
  Judiciary = 'judiciary',
  LawEnforcement = 'law_enforcement',
  LawPractice = 'law_practice',
  LegalServices = 'legal_services',
  LegislativeOffice = 'legislative_office',
  LeisureAndTravelAndTourism = 'leisure_and_travel_and_tourism',
  Libraries = 'libraries',
  LogisticsAndSupplyChain = 'logistics_and_supply_chain',
  LuxuryGoodsAndJewelry = 'luxury_goods_and_jewelry',
  Machinery = 'machinery',
  ManagementConsulting = 'management_consulting',
  Maritime = 'maritime',
  MarketResearch = 'market_research',
  MarketingAndAdvertising = 'marketing_and_advertising',
  MechanicalOrIndustrialEngineering = 'mechanical_or_industrial_engineering',
  MediaProduction = 'media_production',
  MedicalDevices = 'medical_devices',
  MedicalPractice = 'medical_practice',
  MentalHealthCare = 'mental_health_care',
  Military = 'military',
  MiningAndMetals = 'mining_and_metals',
  MotionPicturesAndFilm = 'motion_pictures_and_film',
  MuseumsAndInstitutions = 'museums_and_institutions',
  Music = 'music',
  Nanotechnology = 'nanotechnology',
  Newspapers = 'newspapers',
  NonprofitOrganizationManagement = 'nonprofit_organization_management',
  OilAndGas = 'oil_and_gas',
  OnlineMedia = 'online_media',
  OutsourcingOrOffshoring = 'outsourcing_or_offshoring',
  PackageOrFreightDelivery = 'package_or_freight_delivery',
  PackagingAndContainers = 'packaging_and_containers',
  PaperAndForestProducts = 'paper_and_forest_products',
  PerformingArts = 'performing_arts',
  Pharmaceuticals = 'pharmaceuticals',
  Philanthropy = 'philanthropy',
  Photography = 'photography',
  Plastics = 'plastics',
  PoliticalOrganization = 'political_organization',
  PrimaryOrSecondaryEducation = 'primary_or_secondary_education',
  Printing = 'printing',
  ProfessionalTrainingAndCoaching = 'professional_training_and_coaching',
  ProgramDevelopment = 'program_development',
  PublicPolicy = 'public_policy',
  PublicRelationsAndCommunications = 'public_relations_and_communications',
  PublicSafety = 'public_safety',
  Publishing = 'publishing',
  RailroadManufacture = 'railroad_manufacture',
  Ranching = 'ranching',
  RealEstate = 'real_estate',
  RecreationalFacilitiesAndServices = 'recreational_facilities_and_services',
  ReligiousInstitutions = 'religious_institutions',
  RenewablesAndEnvironment = 'renewables_and_environment',
  Research = 'research',
  Restaurants = 'restaurants',
  Retail = 'retail',
  SecurityAndInvestigations = 'security_and_investigations',
  Semiconductors = 'semiconductors',
  Shipbuilding = 'shipbuilding',
  SportingGoods = 'sporting_goods',
  Sports = 'sports',
  StaffingAndRecruiting = 'staffing_and_recruiting',
  Supermarkets = 'supermarkets',
  Telecommunications = 'telecommunications',
  Textiles = 'textiles',
  ThinkTanks = 'think_tanks',
  Tobacco = 'tobacco',
  TranslationAndLocalization = 'translation_and_localization',
  TransportationgAndTruckingAndRailroad = 'transportationg_and_trucking_and_railroad',
  Utilities = 'utilities',
  VentureCapitalAndPrivateEquity = 'venture_capital_and_private_equity',
  Veterinary = 'veterinary',
  Warehousing = 'warehousing',
  Wholesale = 'wholesale',
  WineAndSpirits = 'wine_and_spirits',
  Wireless = 'wireless',
  WritingAndEditing = 'writing_and_editing',
}

export const mapIndustry = new Map([
  [EnumIndustry.Accounting, 'Accounting'],
  [EnumIndustry.AirlinesAndAviation, 'Airlines/Aviation'],
  [EnumIndustry.AlternativeDisputeResolution, 'Alternative Dispute Resolution'],
  [EnumIndustry.AlternativeMedicine, 'Alternative Medicine'],
  [EnumIndustry.Animation, 'Animation'],
  [EnumIndustry.ApparelAndFashion, 'Apparel & Fashion'],
  [EnumIndustry.ArchitectureAndPlanning, 'Architecture & Planning'],
  [EnumIndustry.ArtsAndCrafts, 'Arts & Crats'],
  [EnumIndustry.Automotive, 'Automotive'],
  [EnumIndustry.AviationAndAerospace, 'Aviation & Aerospace'],
  [EnumIndustry.Banking, 'Banking'],
  [EnumIndustry.Biotechnology, 'Biotechnology'],
  [EnumIndustry.BroadcastMedia, 'Broadcast Media'],
  [EnumIndustry.BuildingMaterials, 'Building Materials'],
  [EnumIndustry.BusinessSuppliesAndEquipment, 'Business Supplies & Equipment'],
  [EnumIndustry.CapitalMarkets, 'Capital Markets'],
  [EnumIndustry.Chemicals, 'Chemicals'],
  [EnumIndustry.CivicAndSocialOrganization, 'Civic & Social Organization'],
  [EnumIndustry.CivilEngineering, 'Civil Engineering'],
  [EnumIndustry.CommercialRealEstate, 'Commercial Real Estate'],
  [EnumIndustry.ComputerAndNetworkSecurity, 'Computer & Network Security'],
  [EnumIndustry.ComputerGames, 'Computer Games'],
  [EnumIndustry.ComputerHardware, 'Computer Hardware'],
  [EnumIndustry.ComputerNetworking, 'Computer Networking'],
  [EnumIndustry.ComputerSoftware, 'Computer Software'],
  [EnumIndustry.Construction, 'Construction'],
  [EnumIndustry.ConsumerElectronics, 'Consumer Electronics'],
  [EnumIndustry.ConsumerGoods, 'Consumer Goods'],
  [EnumIndustry.ConsumerServices, 'Consumer Services'],
  [EnumIndustry.Cosmetics, 'Cosmetics'],
  [EnumIndustry.Dairy, 'Dairy'],
  [EnumIndustry.DefenseAndSpace, 'Defense & Space'],
  [EnumIndustry.Design, 'Design'],
  [EnumIndustry.Elearning, 'E-learning'],
  [EnumIndustry.EducationManagement, 'Education Management'],
  [EnumIndustry.ElectricalOrElectronicManufacturing, 'Electrical or Electronic Manufacturing'],
  [EnumIndustry.Energy, 'Energy'],
  [EnumIndustry.Entertainment, 'Entertainment'],
  [EnumIndustry.EnvironmentalServices, 'Environmental Services'],
  [EnumIndustry.EventsServices, 'Events Services'],
  [EnumIndustry.ExecutiveOffice, 'Executive Office'],
  [EnumIndustry.FacilitiesServices, 'Facilities Services'],
  [EnumIndustry.Farming, 'Farming'],
  [EnumIndustry.FastMovingConsumerGoods, 'Fast-Moving Consumer Goods'],
  [EnumIndustry.FinancialServices, 'Financial Services'],
  [EnumIndustry.FineArt, 'Fine Art'],
  [EnumIndustry.Fishery, 'Fishery'],
  [EnumIndustry.FoodAndBeverages, 'Food & Beverages'],
  [EnumIndustry.FoodProduction, 'Food Production'],
  [EnumIndustry.Fundraising, 'Fundraising'],
  [EnumIndustry.Furniture, 'Furniture'],
  [EnumIndustry.GamblingAndCasinos, 'Gambling & Casinos'],
  [EnumIndustry.GlassAndCeramicsAndConcrete, 'Glass, Ceramics & Concrete'],
  [EnumIndustry.GovernmentAdministration, 'Government Administration'],
  [EnumIndustry.GovernmentRelations, 'Government Relations'],
  [EnumIndustry.GraphicDesign, 'Graphic Design'],
  [EnumIndustry.HealthAndWellnessAndFitness, 'Health, Wellness & Fitness'],
  [EnumIndustry.HigherEducation, 'Higher Education'],
  [EnumIndustry.HospitalAndHealthCare, 'Hospital & Health Care'],
  [EnumIndustry.Hospitality, 'Hospitality'],
  [EnumIndustry.HumanResources, 'Human Resources'],
  [EnumIndustry.ImportAndExport, 'Import & Export'],
  [EnumIndustry.IndividualAndFamilyServices, 'Individual & Family Services'],
  [EnumIndustry.IndustrialAutomation, 'Industrial Automation'],
  [EnumIndustry.InformationServices, 'Information Services'],
  [EnumIndustry.InformationTechnology, 'Information Technology'],
  [EnumIndustry.Insurance, 'Insurance'],
  [EnumIndustry.InternationalAffairs, 'International Affairs'],
  [EnumIndustry.InternationalTradeAndDevelopment, 'International Trade & Development'],
  [EnumIndustry.Internet, 'Internet'],
  [EnumIndustry.InvestmentBanking, 'Investment Banking'],
  [EnumIndustry.InvestmentManagement, 'Investment Management'],
  [EnumIndustry.Judiciary, 'Judiciary'],
  [EnumIndustry.LawEnforcement, 'Law Enforcement'],
  [EnumIndustry.LawPractice, 'Law Practice'],
  [EnumIndustry.LegalServices, 'Legal Services'],
  [EnumIndustry.LegislativeOffice, 'Legislative Office'],
  [EnumIndustry.LeisureAndTravelAndTourism, 'Leisure, Travel & Tourism'],
  [EnumIndustry.Libraries, 'Libraries'],
  [EnumIndustry.LogisticsAndSupplyChain, 'Logistics & Supply Chain'],
  [EnumIndustry.LuxuryGoodsAndJewelry, 'Luxury Goods & Jewelry'],
  [EnumIndustry.Machinery, 'Machinery'],
  [EnumIndustry.ManagementConsulting, 'Management Consulting'],
  [EnumIndustry.Maritime, 'Maritime'],
  [EnumIndustry.MarketResearch, 'Market Research'],
  [EnumIndustry.MarketingAndAdvertising, 'Marketing & Advertising'],
  [EnumIndustry.MechanicalOrIndustrialEngineering, 'Mechanical or Industrial Engineering'],
  [EnumIndustry.MediaProduction, 'Media Production'],
  [EnumIndustry.MedicalDevices, 'Medical Devices'],
  [EnumIndustry.MedicalPractice, 'Medical Practice'],
  [EnumIndustry.MentalHealthCare, 'Mental Health Care'],
  [EnumIndustry.Military, 'Military'],
  [EnumIndustry.MiningAndMetals, 'Mining & Metals'],
  [EnumIndustry.MotionPicturesAndFilm, 'Motion Pictures & Film'],
  [EnumIndustry.MuseumsAndInstitutions, 'Museums & Institutions'],
  [EnumIndustry.Music, 'Music'],
  [EnumIndustry.Nanotechnology, 'Nanotechnology'],
  [EnumIndustry.Newspapers, 'Newspapers'],
  [EnumIndustry.NonprofitOrganizationManagement, 'Nonprofit Organization Management'],
  [EnumIndustry.OilAndGas, 'Oil & Gas'],
  [EnumIndustry.OnlineMedia, 'Online Media'],
  [EnumIndustry.OutsourcingOrOffshoring, 'Outsourcing/Offshoring'],
  [EnumIndustry.PackageOrFreightDelivery, 'Package/Freight Delivery'],
  [EnumIndustry.PackagingAndContainers, 'Packaging & Containers'],
  [EnumIndustry.PaperAndForestProducts, 'Paper & Forest Products'],
  [EnumIndustry.PerformingArts, 'Performing Arts'],
  [EnumIndustry.Pharmaceuticals, 'Pharmaceuticals'],
  [EnumIndustry.Philanthropy, 'Philanthropy'],
  [EnumIndustry.Photography, 'Photography'],
  [EnumIndustry.Plastics, 'Plastics'],
  [EnumIndustry.PoliticalOrganization, 'Political Organization'],
  [EnumIndustry.PrimaryOrSecondaryEducation, 'Primary/Secondary Education'],
  [EnumIndustry.Printing, 'Printing'],
  [EnumIndustry.ProfessionalTrainingAndCoaching, 'Professional Training & Coaching'],
  [EnumIndustry.ProgramDevelopment, 'Program Development'],
  [EnumIndustry.PublicPolicy, 'Public Policy'],
  [EnumIndustry.PublicRelationsAndCommunications, 'Public Relations & Communications'],
  [EnumIndustry.PublicSafety, 'Public Safety'],
  [EnumIndustry.Publishing, 'Publishing'],
  [EnumIndustry.RailroadManufacture, 'Railroad Manufacture'],
  [EnumIndustry.Ranching, 'Ranching'],
  [EnumIndustry.RealEstate, 'Real Estate'],
  [EnumIndustry.RecreationalFacilitiesAndServices, 'Recreational Facilities & Services'],
  [EnumIndustry.ReligiousInstitutions, 'Religious Institutions'],
  [EnumIndustry.RenewablesAndEnvironment, 'Renewables & Environment'],
  [EnumIndustry.Research, 'Research'],
  [EnumIndustry.Restaurants, 'Restaurants'],
  [EnumIndustry.Retail, 'Retail'],
  [EnumIndustry.SecurityAndInvestigations, 'Security & Investigations'],
  [EnumIndustry.Semiconductors, 'Semiconductors'],
  [EnumIndustry.Shipbuilding, 'Shipbuilding'],
  [EnumIndustry.SportingGoods, 'Sporting Goods'],
  [EnumIndustry.Sports, 'Sports'],
  [EnumIndustry.StaffingAndRecruiting, 'Staffing & Recruiting'],
  [EnumIndustry.Supermarkets, 'Supermarkets'],
  [EnumIndustry.Telecommunications, 'Telecommunications'],
  [EnumIndustry.Textiles, 'Textiles'],
  [EnumIndustry.ThinkTanks, 'Think Tanks'],
  [EnumIndustry.Tobacco, 'Tobacco'],
  [EnumIndustry.TranslationAndLocalization, 'Translation & Localization'],
  [EnumIndustry.TransportationgAndTruckingAndRailroad, 'Transportation, Trucking & Railroad'],
  [EnumIndustry.Utilities, 'Utilities'],
  [EnumIndustry.VentureCapitalAndPrivateEquity, 'Venture Capital & Private Equity'],
  [EnumIndustry.Veterinary, 'Veterinary'],
  [EnumIndustry.Warehousing, 'Warehousing'],
  [EnumIndustry.Wholesale, 'Wholesale'],
  [EnumIndustry.WineAndSpirits, 'Wine & Spirits'],
  [EnumIndustry.Wireless, 'Wireless'],
  [EnumIndustry.WritingAndEditing, 'Writing & Editing'],
]);

export enum EnumLanguage {
  Aa = 'aa',
  Ab = 'ab',
  Ae = 'ae',
  Af = 'af',
  Ak = 'ak',
  Am = 'am',
  An = 'an',
  Ar = 'ar',
  As = 'as',
  Av = 'av',
  Ay = 'ay',
  Az = 'az',
  Ba = 'ba',
  Be = 'be',
  Bg = 'bg',
  Bi = 'bi',
  Bm = 'bm',
  Bn = 'bn',
  Bo = 'bo',
  Br = 'br',
  Bs = 'bs',
  Ca = 'ca',
  Ce = 'ce',
  Ch = 'ch',
  Cnr = 'cnr',
  Co = 'co',
  Cr = 'cr',
  Cs = 'cs',
  Cu = 'cu',
  Cv = 'cv',
  Cy = 'cy',
  Da = 'da',
  Dag = 'dag',
  De = 'de',
  Dv = 'dv',
  Dz = 'dz',
  Ee = 'ee',
  El = 'el',
  En = 'en',
  Eo = 'eo',
  Es = 'es',
  Et = 'et',
  Eu = 'eu',
  Fa = 'fa',
  Ff = 'ff',
  Fi = 'fi',
  Fil = 'fil',
  Fj = 'fj',
  Fo = 'fo',
  Fr = 'fr',
  Fy = 'fy',
  Ga = 'ga',
  Gd = 'gd',
  Gl = 'gl',
  Gn = 'gn',
  Gu = 'gu',
  Gv = 'gv',
  Ha = 'ha',
  He = 'he',
  Hi = 'hi',
  Ho = 'ho',
  Hr = 'hr',
  Ht = 'ht',
  Hu = 'hu',
  Hy = 'hy',
  Hz = 'hz',
  Ia = 'ia',
  Id = 'id',
  Ie = 'ie',
  Ig = 'ig',
  Ii = 'ii',
  Ik = 'ik',
  Io = 'io',
  Is = 'is',
  It = 'it',
  Iu = 'iu',
  Ja = 'ja',
  Jv = 'jv',
  Ka = 'ka',
  Kg = 'kg',
  Ki = 'ki',
  Kj = 'kj',
  Kk = 'kk',
  Kl = 'kl',
  Km = 'km',
  Kn = 'kn',
  Ko = 'ko',
  Kr = 'kr',
  Ks = 'ks',
  Ku = 'ku',
  Kv = 'kv',
  Kw = 'kw',
  Ky = 'ky',
  La = 'la',
  Lb = 'lb',
  Lg = 'lg',
  Li = 'li',
  Ln = 'ln',
  Lo = 'lo',
  Lt = 'lt',
  Lu = 'lu',
  Lv = 'lv',
  Mg = 'mg',
  Mh = 'mh',
  Mi = 'mi',
  Mk = 'mk',
  Ml = 'ml',
  Mn = 'mn',
  Mo = 'mo',
  Mr = 'mr',
  Ms = 'ms',
  Mt = 'mt',
  My = 'my',
  Na = 'na',
  Nb = 'nb',
  Nd = 'nd',
  Ne = 'ne',
  Ng = 'ng',
  Nl = 'nl',
  Nn = 'nn',
  No = 'no',
  Nr = 'nr',
  Nso = 'nso',
  Nv = 'nv',
  Ny = 'ny',
  Oc = 'oc',
  Oj = 'oj',
  Om = 'om',
  Or = 'or',
  Os = 'os',
  Pa = 'pa',
  Pi = 'pi',
  Pl = 'pl',
  Ps = 'ps',
  Pt = 'pt',
  Qu = 'qu',
  Rm = 'rm',
  Rn = 'rn',
  Ro = 'ro',
  Ru = 'ru',
  Rw = 'rw',
  Sa = 'sa',
  Sc = 'sc',
  Sd = 'sd',
  Se = 'se',
  Sg = 'sg',
  Si = 'si',
  Sk = 'sk',
  Sl = 'sl',
  Sm = 'sm',
  Sn = 'sn',
  So = 'so',
  Sq = 'sq',
  Sr = 'sr',
  Ss = 'ss',
  St = 'st',
  Su = 'su',
  Sv = 'sv',
  Sw = 'sw',
  Ta = 'ta',
  Te = 'te',
  Tg = 'tg',
  Th = 'th',
  Ti = 'ti',
  Tk = 'tk',
  Tl = 'tl',
  Tn = 'tn',
  To = 'to',
  Tr = 'tr',
  Ts = 'ts',
  Tt = 'tt',
  Tw = 'tw',
  Ty = 'ty',
  Ug = 'ug',
  Uk = 'uk',
  Ur = 'ur',
  Uz = 'uz',
  Ve = 've',
  Vi = 'vi',
  Vo = 'vo',
  Wa = 'wa',
  Wo = 'wo',
  Xh = 'xh',
  Yi = 'yi',
  Yo = 'yo',
  Yue = 'yue',
  Za = 'za',
  Zh = 'zh',
  Zu = 'zu',
}

export const mapLanguage = new Map([
  [EnumLanguage.Aa, 'Afar'],
  [EnumLanguage.Ab, 'Abkhazian'],
  [EnumLanguage.Ae, 'Avestan'],
  [EnumLanguage.Af, 'Afrikaans'],
  [EnumLanguage.Ak, 'Akan'],
  [EnumLanguage.Am, 'Amharic'],
  [EnumLanguage.An, 'Aragonese'],
  [EnumLanguage.Ar, 'Arabic'],
  [EnumLanguage.As, 'Assamese'],
  [EnumLanguage.Av, 'Avaric'],
  [EnumLanguage.Ay, 'Aymara'],
  [EnumLanguage.Az, 'Azerbaijani'],
  [EnumLanguage.Ba, 'Bashkir'],
  [EnumLanguage.Be, 'Belarusian'],
  [EnumLanguage.Bg, 'Bulgarian'],
  [EnumLanguage.Bi, 'Bislama'],
  [EnumLanguage.Bm, 'Bambara'],
  [EnumLanguage.Bn, 'Bengali'],
  [EnumLanguage.Bo, 'Tibetan'],
  [EnumLanguage.Br, 'Breton'],
  [EnumLanguage.Bs, 'Bosnian'],
  [EnumLanguage.Ca, 'Catalan, Valencian'],
  [EnumLanguage.Ce, 'Chechen'],
  [EnumLanguage.Ch, 'Chamorro'],
  [EnumLanguage.Cnr, 'Montenegrin'],
  [EnumLanguage.Co, 'Corsican'],
  [EnumLanguage.Cr, 'Cree'],
  [EnumLanguage.Cs, 'Czech'],
  [
    EnumLanguage.Cu,
    'Church Slavic, Old Slavonic, Church Slavonic, Old Bulgarian, Old Church Slavonic',
  ],
  [EnumLanguage.Cv, 'Chuvash'],
  [EnumLanguage.Cy, 'Welsh'],
  [EnumLanguage.Da, 'Danish'],
  [EnumLanguage.Dag, 'Dagbani'],
  [EnumLanguage.De, 'German'],
  [EnumLanguage.Dv, 'Divehi, Dhivehi, Maldivian'],
  [EnumLanguage.Dz, 'Dzongkha'],
  [EnumLanguage.Ee, 'Ewe'],
  [EnumLanguage.El, 'Greek, Modern (1453–)'],
  [EnumLanguage.En, 'English'],
  [EnumLanguage.Eo, 'Esperanto'],
  [EnumLanguage.Es, 'Spanish, Castilian'],
  [EnumLanguage.Et, 'Estonian'],
  [EnumLanguage.Eu, 'Basque'],
  [EnumLanguage.Fa, 'Persian'],
  [EnumLanguage.Ff, 'Fulah'],
  [EnumLanguage.Fi, 'Finnish'],
  [EnumLanguage.Fil, 'Filipino'],
  [EnumLanguage.Fj, 'Fijian'],
  [EnumLanguage.Fo, 'Faroese'],
  [EnumLanguage.Fr, 'French'],
  [EnumLanguage.Fy, 'Western Frisian'],
  [EnumLanguage.Ga, 'Irish'],
  [EnumLanguage.Gd, 'Gaelic, Scottish Gaelic'],
  [EnumLanguage.Gl, 'Galician'],
  [EnumLanguage.Gn, 'Guarani'],
  [EnumLanguage.Gu, 'Gujarati'],
  [EnumLanguage.Gv, 'Manx'],
  [EnumLanguage.Ha, 'Hausa'],
  [EnumLanguage.He, 'Hebrew'],
  [EnumLanguage.Hi, 'Hindi'],
  [EnumLanguage.Ho, 'Hiri Motu'],
  [EnumLanguage.Hr, 'Croatian'],
  [EnumLanguage.Ht, 'Haitian, Haitian Creole'],
  [EnumLanguage.Hu, 'Hungarian'],
  [EnumLanguage.Hy, 'Armenian'],
  [EnumLanguage.Hz, 'Herero'],
  [EnumLanguage.Ia, 'Interlingua (International Auxiliary Language Association)'],
  [EnumLanguage.Id, 'Indonesian'],
  [EnumLanguage.Ie, 'Interlingue, Occidental'],
  [EnumLanguage.Ig, 'Igbo'],
  [EnumLanguage.Ii, 'Sichuan Yi, Nuosu'],
  [EnumLanguage.Ik, 'Inupiaq'],
  [EnumLanguage.Io, 'Ido'],
  [EnumLanguage.Is, 'Icelandic'],
  [EnumLanguage.It, 'Italian'],
  [EnumLanguage.Iu, 'Inuktitut'],
  [EnumLanguage.Ja, 'Japanese'],
  [EnumLanguage.Jv, 'Javanese'],
  [EnumLanguage.Ka, 'Georgian'],
  [EnumLanguage.Kg, 'Kongo'],
  [EnumLanguage.Ki, 'Kikuyu, Gikuyu'],
  [EnumLanguage.Kj, 'Kuanyama, Kwanyama'],
  [EnumLanguage.Kk, 'Kazakh'],
  [EnumLanguage.Kl, 'Kalaallisut, Greenlandic'],
  [EnumLanguage.Km, 'Central Khmer'],
  [EnumLanguage.Kn, 'Kannada'],
  [EnumLanguage.Ko, 'Korean'],
  [EnumLanguage.Kr, 'Kanuri'],
  [EnumLanguage.Ks, 'Kashmiri'],
  [EnumLanguage.Ku, 'Kurdish'],
  [EnumLanguage.Kv, 'Komi'],
  [EnumLanguage.Kw, 'Cornish'],
  [EnumLanguage.Ky, 'Kirghiz, Kyrgyz'],
  [EnumLanguage.La, 'Latin'],
  [EnumLanguage.Lb, 'Luxembourgish, Letzeburgesch'],
  [EnumLanguage.Lg, 'Ganda'],
  [EnumLanguage.Li, 'Limburgan, Limburger, Limburgish'],
  [EnumLanguage.Ln, 'Lingala'],
  [EnumLanguage.Lo, 'Lao'],
  [EnumLanguage.Lt, 'Lithuanian'],
  [EnumLanguage.Lu, 'Luba-Katanga'],
  [EnumLanguage.Lv, 'Latvian'],
  [EnumLanguage.Mg, 'Malagasy'],
  [EnumLanguage.Mh, 'Marshallese'],
  [EnumLanguage.Mi, 'Maori'],
  [EnumLanguage.Mk, 'Macedonian'],
  [EnumLanguage.Ml, 'Malayalam'],
  [EnumLanguage.Mn, 'Mongolian'],
  [EnumLanguage.Mo, 'Moldovan'],
  [EnumLanguage.Mr, 'Marathi'],
  [EnumLanguage.Ms, 'Malay'],
  [EnumLanguage.Mt, 'Maltese'],
  [EnumLanguage.My, 'Burmese'],
  [EnumLanguage.Na, 'Nauru'],
  [EnumLanguage.Nb, 'Norwegian Bokmål'],
  [EnumLanguage.Nd, 'North Ndebele'],
  [EnumLanguage.Ne, 'Nepali'],
  [EnumLanguage.Ng, 'Ndonga'],
  [EnumLanguage.Nl, 'Dutch, Flemish'],
  [EnumLanguage.Nn, 'Norwegian Nynorsk'],
  [EnumLanguage.No, 'Norwegian'],
  [EnumLanguage.Nr, 'South Ndebele'],
  [EnumLanguage.Nso, 'Northern Sotho'],
  [EnumLanguage.Nv, 'Navajo, Navaho'],
  [EnumLanguage.Ny, 'Chichewa, Chewa, Nyanja'],
  [EnumLanguage.Oc, 'Occitan'],
  [EnumLanguage.Oj, 'Ojibwa'],
  [EnumLanguage.Om, 'Oromo'],
  [EnumLanguage.Or, 'Oriya'],
  [EnumLanguage.Os, 'Ossetian, Ossetic'],
  [EnumLanguage.Pa, 'Punjabi, Panjabi'],
  [EnumLanguage.Pi, 'Pali'],
  [EnumLanguage.Pl, 'Polish'],
  [EnumLanguage.Ps, 'Pashto, Pushto'],
  [EnumLanguage.Pt, 'Portuguese'],
  [EnumLanguage.Qu, 'Quechua'],
  [EnumLanguage.Rm, 'Romansh'],
  [EnumLanguage.Rn, 'Rundi'],
  [EnumLanguage.Ro, 'Romanian, Moldavian, Moldovan'],
  [EnumLanguage.Ru, 'Russian'],
  [EnumLanguage.Rw, 'Kinyarwanda'],
  [EnumLanguage.Sa, 'Sanskrit'],
  [EnumLanguage.Sc, 'Sardinian'],
  [EnumLanguage.Sd, 'Sindhi'],
  [EnumLanguage.Se, 'Northern Sami'],
  [EnumLanguage.Sg, 'Sango'],
  [EnumLanguage.Si, 'Sinhala, Sinhalese'],
  [EnumLanguage.Sk, 'Slovak'],
  [EnumLanguage.Sl, 'Slovenian'],
  [EnumLanguage.Sm, 'Samoan'],
  [EnumLanguage.Sn, 'Shona'],
  [EnumLanguage.So, 'Somali'],
  [EnumLanguage.Sq, 'Albanian'],
  [EnumLanguage.Sr, 'Serbian'],
  [EnumLanguage.Ss, 'Swati'],
  [EnumLanguage.St, 'Southern Sotho'],
  [EnumLanguage.Su, 'Sundanese'],
  [EnumLanguage.Sv, 'Swedish'],
  [EnumLanguage.Sw, 'Swahili'],
  [EnumLanguage.Ta, 'Tamil'],
  [EnumLanguage.Te, 'Telugu'],
  [EnumLanguage.Tg, 'Tajik'],
  [EnumLanguage.Th, 'Thai'],
  [EnumLanguage.Ti, 'Tigrinya'],
  [EnumLanguage.Tk, 'Turkmen'],
  [EnumLanguage.Tl, 'Tagalog'],
  [EnumLanguage.Tn, 'Tswana'],
  [EnumLanguage.To, 'Tonga (Tonga Islands)'],
  [EnumLanguage.Tr, 'Turkish'],
  [EnumLanguage.Ts, 'Tsonga'],
  [EnumLanguage.Tt, 'Tatar'],
  [EnumLanguage.Tw, 'Twi'],
  [EnumLanguage.Ty, 'Tahitian'],
  [EnumLanguage.Ug, 'Uighur, Uyghur'],
  [EnumLanguage.Uk, 'Ukrainian'],
  [EnumLanguage.Ur, 'Urdu'],
  [EnumLanguage.Uz, 'Uzbek'],
  [EnumLanguage.Ve, 'Venda'],
  [EnumLanguage.Vi, 'Vietnamese'],
  [EnumLanguage.Vo, 'Volapük'],
  [EnumLanguage.Wa, 'Walloon'],
  [EnumLanguage.Wo, 'Wolof'],
  [EnumLanguage.Xh, 'Xhosa'],
  [EnumLanguage.Yi, 'Yiddish'],
  [EnumLanguage.Yo, 'Yoruba'],
  [EnumLanguage.Yue, 'Yue'],
  [EnumLanguage.Za, 'Zhuang, Chuang'],
  [EnumLanguage.Zh, 'Chinese'],
  [EnumLanguage.Zu, 'Zulu'],
]);

export enum LogEventType {
  Flag = 'flag',
}

export enum EnumEventType {
  Milestone = 'milestone',
  Event = 'event',
  Task = 'task',
  Subtask = 'subtask',
  Payment = 'payment',
  PitchDeadline = 'pitch_deadline',
}

export enum EnumProjectContractStatus {
  Accepted = 'accepted',
  Negotiation = 'negotiation',
  Canceled = 'canceled',
  Draft = 'draft',
  Active = 'active',
}

export enum EnumProjectHourlyRate {
  Bt_25_49 = 'bt_25_49',
  Bt_50_99 = 'bt_50_99',
  Bt_100_149 = 'bt_100_149',
  Bt_150_199 = 'bt_150_199',
  Bt_200_299 = 'bt_200_299',
  Gte_300 = 'gte_300',
  Lt_25 = 'lt_25',
  NA = 'n_a',
}

export const mapHourlyRate = new Map([
  [EnumProjectHourlyRate.Lt_25, '< 25'],
  [EnumProjectHourlyRate.Bt_25_49, '25 - 49'],
  [EnumProjectHourlyRate.Bt_50_99, '50 - 99'],
  [EnumProjectHourlyRate.Bt_100_149, '100 - 149'],
  [EnumProjectHourlyRate.Bt_150_199, '150 - 199'],
  [EnumProjectHourlyRate.Bt_200_299, '200 - 299'],
  [EnumProjectHourlyRate.Gte_300, '300+'],
  [EnumProjectHourlyRate.NA, 'n_a'],
]);

export enum EnumProjectMilestoneStatus {
  Approved = 'approved',
  Completed = 'completed',
  InProgress = 'in_progress',
  Negotiation = 'negotiation',
  Pending = 'pending',
  UnderReview = 'under_review',
}

export enum EnumProjectPaymentStatus {
  Completed = 'completed',
  PendingBuyerInvoice = 'pending_buyer_invoice',
  PendingBuyerPayment = 'pending_buyer_payment',
  PendingMilestoneApproval = 'pending_milestone_approval',
  PendingSellerInvoice = 'pending_seller_invoice',
  PendingSellerPayment = 'pending_seller_payment',
}

export enum EnumProjectSize {
  Bt_5k_10k = 'bt_5k_10k',
  Bt_10k_25k = 'bt_10k_25k',
  Bt_25k_50k = 'bt_25k_50k',
  Bt_50k_75k = 'bt_50k_75k',
  Bt_75k_100k = 'bt_75k_100k',
  Bt_100k_150k = 'bt_100k_150k',
  Bt_150k_200k = 'bt_150k_200k',
  Bt_200k_250k = 'bt_200k_250k',
  Gt_250k = 'gt_250k',
  Lt_5k = 'lt_5k',
}

export const mapProjectSize = new Map([
  [EnumProjectSize.Bt_5k_10k, '5,000 - 10,000'],
  [EnumProjectSize.Bt_10k_25k, '10,000 - 25,000'],
  [EnumProjectSize.Bt_25k_50k, '25,000 - 50,000'],
  [EnumProjectSize.Bt_50k_75k, '50,000 - 75,000'],
  [EnumProjectSize.Bt_75k_100k, '75,000 - 100,000'],
  [EnumProjectSize.Bt_100k_150k, '100,000 - 150,000'],
  [EnumProjectSize.Bt_150k_200k, '150,000 - 200,000'],
  [EnumProjectSize.Bt_200k_250k, '200,000 - 250,000'],
  [EnumProjectSize.Gt_250k, '250,000+'],
  [EnumProjectSize.Lt_5k, '< 5,000'],
]);

export enum EnumProjectStatus {
  Canceled = 'canceled',
  Completed = 'completed',
  InProgress = 'in_progress',
  Pending = 'pending',
}

export enum EnumProjectSubtaskStatus {
  Canceled = 'canceled',
  Completed = 'completed',
  Pending = 'pending',
}

export enum EnumProjectTaskStatus {
  Canceled = 'canceled',
  Completed = 'completed',
  InProgress = 'in_progress',
  Pending = 'pending',
}

export enum EnumProjectType {
  OneTime = 'one_time',
  Retainer = 'retainer',
}

export enum EnumPublicationStatus {
  AwaitingApproval = 'awaiting_approval',
  Deleted = 'deleted',
  Draft = 'draft',
  Private = 'private',
  Public = 'public',
  Rejected = 'rejected',
}

export enum EnumService {
  Animation = 'animation',
  AppDesign = 'app_design',
  AppDevelopment = 'app_development',
  AugmentedReality = 'augmented_reality',
  Blockchain = 'blockchain',
  Branding = 'branding',
  CgiProduction = 'cgi_production',
  Chatbot = 'chatbot',
  CommunityManagement = 'community_management',
  ContentMarketing = 'content_marketing',
  Copywriting = 'copywriting',
  DataAnalytics = 'data_analytics',
  DataEngineering = 'data_engineering',
  DataScience = 'data_science',
  DesignStrategy = 'design_strategy',
  DevOps = 'devops',
  DigitalStrategy = 'digital_strategy',
  Ecommerce = 'ecommerce',
  EmailMarketing = 'email_marketing',
  EventMarketing = 'event_marketing',
  ExtendedReality = 'extended_reality',
  GameDevelopment = 'game_development',
  GraphicDesign = 'graphic_design',
  InfluencerMarketing = 'influencer_marketing',
  InstoreMarketing = 'instore_marketing',
  MachineLearning = 'machine_learning',
  MarketResearch = 'market_research',
  MediaBuying = 'media_buying',
  MobileAdvertising = 'mobile_advertising',
  MobileAppDesign = 'mobile_app_design',
  MobileAppDevelopment = 'mobile_app_development',
  MotionDesign = 'motion_design',
  PaidSearchMarketing = 'paid_search_marketing',
  PerformanceMarketing = 'performance_marketing',
  PublicRelations = 'public_relations',
  SearchEngineOptimization = 'search_engine_optimization',
  SocialMediaAdvertising = 'social_media_advertising',
  SocialMediaManagement = 'social_media_management',
  UserExperienceDesign = 'user_experience_design',
  UserInterfaceDesign = 'user_interface_design',
  Video = 'video',
  VirtualReality = 'virtual_reality',
  WebDesign = 'web_design',
  WebDevelopment = 'web_development',
}

export const mapService = new Map([
  [EnumService.Animation, 'Animation'],
  [EnumService.AppDesign, 'App Design'],
  [EnumService.AppDevelopment, 'App Development'],
  [EnumService.AugmentedReality, 'Augmented Reality (AR)'],
  [EnumService.Blockchain, 'Blockchain'],
  [EnumService.Branding, 'Branding'],
  [EnumService.CgiProduction, 'CGI Production'],
  [EnumService.Chatbot, 'Chatbot'],
  [EnumService.CommunityManagement, 'Community Management'],
  [EnumService.ContentMarketing, 'Content Marketing'],
  [EnumService.Copywriting, 'Copywriting'],
  [EnumService.DataAnalytics, 'Data Analytics'],
  [EnumService.DataEngineering, 'Data Engineering'],
  [EnumService.DataScience, 'Data Science'],
  [EnumService.DesignStrategy, 'Design Strategy'],
  [EnumService.DevOps, 'DevOps'],
  [EnumService.DigitalStrategy, 'Digital Strategy'],
  [EnumService.Ecommerce, 'E-commerce'],
  [EnumService.EmailMarketing, 'Email Marketing'],
  [EnumService.EventMarketing, 'Event Marketing'],
  [EnumService.ExtendedReality, 'Extended Reality (XR)'],
  [EnumService.GameDevelopment, 'Game Development'],
  [EnumService.GraphicDesign, 'Graphic Design'],
  [EnumService.InfluencerMarketing, 'Influencer Marketing'],
  [EnumService.InstoreMarketing, 'In-store Marketing'],
  [EnumService.MachineLearning, 'Machine Learning'],
  [EnumService.MarketResearch, 'Market Research'],
  [EnumService.MediaBuying, 'Media Buying'],
  [EnumService.MobileAdvertising, 'Mobile Advertising'],
  [EnumService.MobileAppDesign, 'Mobile App Design'],
  [EnumService.MobileAppDevelopment, 'Mobile App Development'],
  [EnumService.MotionDesign, 'Motion Design'],
  [EnumService.PaidSearchMarketing, 'Paid Search Marketing'],
  [EnumService.PerformanceMarketing, 'Performance Marketing'],
  [EnumService.PublicRelations, 'Public Relations'],
  [EnumService.SearchEngineOptimization, 'Search Engine Optimization (SEO)'],
  [EnumService.SocialMediaAdvertising, 'Social Media Advertising'],
  [EnumService.SocialMediaManagement, 'Social Media Management'],
  [EnumService.UserExperienceDesign, 'User Experience (UX) Design'],
  [EnumService.UserInterfaceDesign, 'User Interface (UI) Design'],
  [EnumService.Video, 'Video'],
  [EnumService.VirtualReality, 'Virtual Reality (VR)'],
  [EnumService.WebDesign, 'Web Design'],
  [EnumService.WebDevelopment, 'Web Development'],
]);

export enum EnumTechLanguage {
  Apl = 'apl',
  Assembly = 'assembly',
  C = 'c',
  Cobol = 'cobol',
  Clojure = 'clojure',
  Cpp = 'cpp',
  Crystal = 'crystal',
  Csharp = 'csharp',
  Dart = 'dart',
  Delphi = 'delphi',
  Elixir = 'elixir',
  Erlang = 'erlang',
  Fsharp = 'fsharp',
  Go = 'go',
  Groovy = 'groovy',
  HtmlAndCss = 'html_and_css',
  Haskell = 'haskell',
  Java = 'java',
  JavaScript = 'javascript',
  Julia = 'julia',
  Kotlin = 'kotlin',
  Lisp = 'lisp',
  Matlab = 'matlab',
  ObjectiveC = 'objective_c',
  Php = 'php',
  Perl = 'perl',
  PowerShell = 'powershell',
  Python = 'python',
  R = 'r',
  Ruby = 'ruby',
  Rust = 'rust',
  Sql = 'sql',
  Scala = 'scala',
  Swift = 'swift',
  TypeScript = 'typescript',
  UnixShell = 'unix_shell',
  VisualBasic = 'visual_basic',
}

export const mapTechLanguage = new Map([
  [EnumTechLanguage.Apl, 'APL'],
  [EnumTechLanguage.Assembly, 'Assembly'],
  [EnumTechLanguage.C, 'C'],
  [EnumTechLanguage.Cobol, 'COBOL'],
  [EnumTechLanguage.Clojure, 'Clojure'],
  [EnumTechLanguage.Cpp, 'C++'],
  [EnumTechLanguage.Crystal, 'Crystal'],
  [EnumTechLanguage.Csharp, 'C#'],
  [EnumTechLanguage.Dart, 'Dart'],
  [EnumTechLanguage.Delphi, 'Delphi'],
  [EnumTechLanguage.Elixir, 'Elixir'],
  [EnumTechLanguage.Erlang, 'Erlang'],
  [EnumTechLanguage.Fsharp, 'F#'],
  [EnumTechLanguage.Go, 'Go'],
  [EnumTechLanguage.Groovy, 'Groovy'],
  [EnumTechLanguage.HtmlAndCss, 'HTML & CSS'],
  [EnumTechLanguage.Haskell, 'Haskell'],
  [EnumTechLanguage.Java, 'Java'],
  [EnumTechLanguage.JavaScript, 'JavaScript'],
  [EnumTechLanguage.Julia, 'Julia'],
  [EnumTechLanguage.Kotlin, 'Kotlin'],
  [EnumTechLanguage.Lisp, 'Lisp'],
  [EnumTechLanguage.Matlab, 'MATLAB'],
  [EnumTechLanguage.ObjectiveC, 'Objective-C'],
  [EnumTechLanguage.Php, 'PHP'],
  [EnumTechLanguage.Perl, 'Perl'],
  [EnumTechLanguage.PowerShell, 'PowerShell'],
  [EnumTechLanguage.Python, 'Python'],
  [EnumTechLanguage.R, 'R'],
  [EnumTechLanguage.Ruby, 'Ruby'],
  [EnumTechLanguage.Rust, 'Rust'],
  [EnumTechLanguage.Sql, 'SQL'],
  [EnumTechLanguage.Scala, 'Scala'],
  [EnumTechLanguage.Swift, 'Swift'],
  [EnumTechLanguage.TypeScript, 'TypeScript'],
  [EnumTechLanguage.UnixShell, 'Unix Shell'],
  [EnumTechLanguage.VisualBasic, 'Visual Basic'],
]);

export enum EnumTechPlatform {
  AmazonWebServices = 'amazon_web_services',
  Android = 'android',
  Azure = 'azure',
  DigitalOcean = 'digitalocean',
  Docker = 'docker',
  GoogleCloud = 'google_cloud',
  Heroku = 'heroku',
  IbmCloud = 'ibm_cloud',
  Kubernetes = 'kubernetes',
  Lxc = 'lxc',
  Linux = 'linux',
  MacOs = 'macos',
  OracleCloud = 'oracle_cloud',
  SalesforcePlatform = 'salesforce_platform',
  Windows = 'windows',
  IOs = 'ios',
}

export enum EnumTechTool {
  AsPdotNet = 'aspdotnet',
  Angular = 'angular',
  AngularJs = 'angularjs',
  ApacheHBase = 'apache_hbase',
  ApacheSpark = 'apache_spark',
  BackboneJs = 'backbonejs',
  Bootstrap = 'bootstrap',
  CakePhp = 'cakephp',
  Cassandra = 'cassandra',
  Cordova = 'cordova',
  Django = 'django',
  Drupal = 'drupal',
  Elasticsearch = 'elasticsearch',
  Electron = 'electron',
  EmberJs = 'emberjs',
  Express = 'express',
  FastApi = 'fastapi',
  Firebase = 'firebase',
  Flask = 'flask',
  Flutter = 'flutter',
  Gatsby = 'gatsby',
  Hadoop = 'hadoop',
  IbmDb2 = 'ibm_db2',
  Joomla = 'joomla',
  Laravel = 'laravel',
  Meteor = 'meteor',
  MicrosoftSqlServer = 'microsoft_sql_server',
  MongoDb = 'mongodb',
  MySqlOrMariaDb = 'mysql_or_mariadb',
  NextJs = 'nextjs',
  NodeJs = 'nodejs',
  NumPy = 'numpy',
  OracleDatabase = 'oracle_database',
  Pandas = 'pandas',
  Play = 'play',
  PostgreSql = 'postgresql',
  PyTorch = 'pytorch',
  Qt = 'qt',
  Quasar = 'quasar',
  React = 'react',
  ReactNative = 'react_native',
  Redis = 'redis',
  RubyOnRails = 'ruby_on_rails',
  SqLite = 'sqlite',
  SailsJs = 'sailsjs',
  Spring = 'spring',
  Struts = 'struts',
  Svelte = 'svelte',
  Symfony = 'symfony',
  TensorFlow = 'tensorflow',
  Unity = 'unity',
  UnrealEngine = 'unreal_engine',
  VueJs = 'vuejs',
  WordPress = 'wordpress',
  DotNet = 'dotnet',
  JQuery = 'jquery',
}

export enum EnumToolType {
  Bar = 'bar',
  Baz = 'baz',
  Foo = 'foo',
}

export enum EnumVerificationStatus {
  TdaVerified = 'tda_verified',
  Unverified = 'unverified',
  Verified = 'verified',
}

export enum EnumTutorialStatus {
  ContractDetail = 'contract_detail_screen',
  EndToEnd = 'end_to_end',
  IndividualContractDetail = 'individual_contract_detail',
  IndividualPayment = 'individual_payment',
  MilestoneDetail = 'milestone_detail',
  PaymentDetail = 'payment_detail',
  ProjectDetail = 'project_detail',
  TaskDetail = 'task_detail',
  SubtaskDetail = 'subtask_detail',
  ActiveProjectDetail = 'active_project_detail',
}

export enum UserRoles {
  Verified = 'verified',
  Admin = 'admin',
}

export enum CompanyRole {
  Buyer = 'buyer',
  SellerTool = 'seller_tool',
  SellerService = 'seller_service',
}

export enum MembershipRole {
  Owner = 'owner',
  Admin = 'admin',
  Editor = 'editor',
}
export const mapMembershipRole = new Map([
  [MembershipRole.Owner, 'Owner'],
  [MembershipRole.Admin, 'Admin'],
  [MembershipRole.Editor, 'Editor'],
]);

export enum PaymentStatus {
  Pending = 'pending',
  InProgress = 'in_progress',
  Late = 'late',
  Paid = 'paid',
}

export enum EnumNotificationCategory {
  Content = 'content',
  Marketplace = 'marketplace',
  E2EManagment = 'e2e_managment',
  Achievements = 'achievements',
  TDCNotifications = 'tdc_notifications',
  Premium = 'premium',
  PostRegistration = 'post_registration',
  Calendar = 'calendar',
}

export enum EnumFlagStatus {
  New = 'new',
  InProgress = 'in_progress',
  Resolved = 'resolved',
}

export enum EnumNotificationType {
  Regular = 'regular',
  Blue = 'blue',
  Orange = 'orange',
  Green = 'green',
  Red = 'red',
}

export enum EnumScreenList {
  ProjectDetail = 'project_detail',
  MilestoneScreen = 'milestone_screen',
  TimedProjectScreen = 'timed_project_screen',
  TimedProjectDetailScreen = 'timed_project_detail_screen',
  PitchScreen = 'pitch_screen',
  PitchDetailScreen = 'pitch_detail_screen',
  CaseStudyScreen = 'case_study_screen',
  DashboardHome = 'dashboard_home',
  Calendar = 'calendar',
}

export enum EnumNotificationSubcategory {
  ArticlePublished = 'article_published',
  CaseStudyPublished = 'case_study_published',
  NewProject = 'new_project',
  ApproveMilestone = 'approve_milestone',
  CompleteMilestone = 'complete_milestone',
  PaidMilestone = 'paid_milestone',
  Unflag = 'unflag',
  Flag = 'flag',
  ApproveContract = 'approve_contract',
  SubmittedContract = 'submitted_contract',
  BannerProjectReady = 'banner_project_ready',
  BannerProjectStarted = 'banner_project_started',
  BannerCreatorSubmittedContract = 'banner_creator_submitted_contract',
  BannerOtherSubmittedContract = 'banner_other_submitted_contract',
  BannerCreatorSubmittedAnnex = 'banner_creator_submitted_annex',
  BannerOtherSubmittedAnnex = 'banner_other_submitted_annex',
  BannerReviewedContract = 'banner_reviewed_contract',
  BannerOtherReviewedContract = 'banner_other_reviewed_contract',
  BannerReviewedAnnex = 'banner_reviewed_annex',
  BannerOtherReviewedAnnex = 'banner_other_reviewed_annex',
  BannerApproveContract = 'banner_approve_contract',
  BannerApproveAnnex = 'banner_approve_annex',
  BannerAnnexApplied = 'banner_annex_applied',
  BannerApproveMilestoneBuyer = 'banner_approve_milestone_buyer',
  BannerApproveMilestoneSeller = 'banner_approve_milestone_seller',
  BannerCompleteMilestoneBuyer = 'banner_complete_milestone_buyer',
  BannerCompleteMilestoneSeller = 'banner_complete_milestone_seller',
  BannerPaymentDue = 'banner_payment_due',
  BannerMilestonePaid = 'banner_milestone_paid',
  BannerRecurringMilestone = 'banner_recurring_milestone',
  BannerFlagCreator = 'banner_flag_creator',
  BannerFlagOther = 'banner_flag_other',
  BannerFlagResolved = 'banner_flag_resolved',
  BannerTimedProjectSubmitted = 'banner_timed_project_submitted',
  BannerTimedProjectRejected = 'banner_timed_project_rejected',
  BannerTimedProjectDraftSaved = 'banner_timed_project_draft_saved',
  BannerTimedProjectEditSubmitted = 'banner_timed_project_edit_submitted',
  BannerTimedProjectEditRejected = 'banner_timed_project_edit_rejected',
  BannerTimedProjectDeletionRequested = 'banner_timed_project_deletion_requested',
  BannerPitchSubmitted = 'banner_pitch_submitted',
  BannerPitchRejected = 'banner_pitch_rejected',
  BannerPitchEditSubmitted = 'banner_pitch_edit_submitted',
  BannerPitchEditRejected = 'banner_pitch_edit_rejected',
  BannerPitchApprovedBuyer = 'banner_pitch_approved_buyer',
  BannerPitchApprovedSeller = 'banner_pitch_approved_seller',
  BannerPitchRound2ApprovedSeller = 'banner_pitch_round_2_approved_seller',
  BannerPitchRound2ApprovedBuyer = 'banner_pitch_round_2_approved_buyer',
  BannerPitchRejectedManually = 'banner_pitch_rejected_manually',
  BannerPitchRejectedAutomatically = 'banner_pitch_rejected_automatically',
  BannerPitchHired = 'banner_pitch_hired',
  BannerPitchDeletionRequested = 'banner_pitch_deletion_requested',
  BannerPitchDeleted = 'banner_pitch_deleted',
  BannerCaseStudyApproved = 'banner_case_study_approved',
  BannerCaseStudyRejected = 'banner_case_study_rejected',
  BannerCaseStudyEditRejected = 'banner_case_study_edit_rejected',
  BannerCaseStudyReviewed = 'banner_case_study_reviewed',
  BannerBuyerProfileApproved = 'banner_buyer_profile_approved',
  BannerSellerProfileApproved = 'banner_seller_profile_approved',
  BannerSellerProfilePending = 'banner_seller_profile_pending',
  BannerSellerProfileRejected = 'banner_seller_profile_rejected',
  BannerEventInvitePending = 'banner_event_invite_pending',
  ReviewContract = 'review_contract',
  ContractReady = 'contract_ready',
  ApproveAnnex = 'approve_annex',
  SubmittedAnnex = 'submitted_annex',
  ReviewAnnex = 'review_annex',
  AnnexReady = 'annex_ready',
  TerminateContract = 'terminate_contract',
  CancelContract = 'cancel_contract',
  PaymentDue = 'payment_due',
  PaymentOverdue = 'payment_overdue',
  CreateTask = 'create_task',
  DeleteTask = 'delete_task',
  ApproveCaseStudy = 'approve_case_study',
  RejectCaseStudy = 'reject_case_study',
  ApproveCaseStudyEdit = 'approve_case_study_edit',
  RejectCaseStudyEdit = 'reject_case_study_edit',
  CaseStudyReviewLive = 'case_study_review_live',
  CaseStudyRequestReview = 'case_study_request_review',
  ApproveTimedProject = 'approve_timed_project',
  RejectTimedProject = 'reject_timed_project',
  NewTimedProject = 'new_timed_project',
  ApproveTimedProjectEditBuyer = 'approve_timed_project_edit_buyer',
  ApproveTimedProjectEditSeller = 'approve_timed_project_edit_seller',
  RejectTimedProjectEdit = 'reject_timed_project_edit',
  TimedProjectDeadlinePassed = 'timed_project_deadline_passed',
  DeleteTimedProjectBuyer = 'delete_timed_project_buyer',
  DeleteTimedProjectSeller = 'delete_timed_project_seller',
  DeleteTimedProjectCanceled = 'delete_timed_project_canceled',
  PitchSubmittedSeller = 'pitch_submitted_seller',
  PitchSubmittedBuyer = 'pitch_submitted_buyer',
  PitchRejected = 'pitch_rejected',
  PitchEditAcceptedSeller = 'pitch_edit_accepted_seller',
  PitchEditAcceptedBuyer = 'pitch_edit_accepted_buyer',
  PitchDeletedSeller = 'pitch_deleted_seller',
  PitchDeletedBuyer = 'pitch_deleted_buyer',
  PitchRound2Accepted = 'pitch_round_2_accepted',
  PitchRound1Rejected = 'pitch_round_1_rejected',
  PitchHired = 'pitch_hired',
  PitchRound2Rejected = 'pitch_round_2_rejected',
  PitchAutomaticallyRejected = 'pitch_automatically_rejected',
  DeletePitchCanceled = 'delete_pitch_canceled',
}

export const mapNotificationSubcategories = new Map([
  [EnumNotificationSubcategory.ArticlePublished, EnumNotificationCategory.Content],
  [EnumNotificationSubcategory.CaseStudyPublished, EnumNotificationCategory.Content],
  [EnumNotificationSubcategory.NewProject, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.ApproveMilestone, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.CompleteMilestone, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.PaidMilestone, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.Unflag, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.Flag, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.ApproveContract, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.SubmittedContract, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.BannerProjectReady, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.BannerProjectStarted, EnumNotificationCategory.E2EManagment],
  [
    EnumNotificationSubcategory.BannerCreatorSubmittedContract,
    EnumNotificationCategory.E2EManagment,
  ],
  [EnumNotificationSubcategory.BannerOtherSubmittedContract, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.BannerCreatorSubmittedAnnex, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.BannerOtherSubmittedAnnex, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.BannerReviewedContract, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.BannerOtherReviewedContract, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.BannerReviewedAnnex, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.BannerOtherReviewedAnnex, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.BannerApproveContract, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.BannerApproveAnnex, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.BannerAnnexApplied, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.BannerApproveMilestoneSeller, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.BannerApproveMilestoneBuyer, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.BannerCompleteMilestoneBuyer, EnumNotificationCategory.E2EManagment],
  [
    EnumNotificationSubcategory.BannerCompleteMilestoneSeller,
    EnumNotificationCategory.E2EManagment,
  ],
  [EnumNotificationSubcategory.BannerPaymentDue, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.BannerMilestonePaid, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.BannerRecurringMilestone, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.BannerFlagOther, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.BannerFlagCreator, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.BannerFlagResolved, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.BannerTimedProjectSubmitted, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.BannerTimedProjectRejected, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.BannerTimedProjectDraftSaved, EnumNotificationCategory.Marketplace],
  [
    EnumNotificationSubcategory.BannerTimedProjectEditSubmitted,
    EnumNotificationCategory.Marketplace,
  ],
  [
    EnumNotificationSubcategory.BannerTimedProjectEditRejected,
    EnumNotificationCategory.Marketplace,
  ],
  [
    EnumNotificationSubcategory.BannerTimedProjectDeletionRequested,
    EnumNotificationCategory.Marketplace,
  ],
  [EnumNotificationSubcategory.BannerPitchSubmitted, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.BannerPitchRejected, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.BannerPitchEditRejected, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.BannerPitchEditSubmitted, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.BannerPitchApprovedBuyer, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.BannerPitchApprovedSeller, EnumNotificationCategory.Marketplace],
  [
    EnumNotificationSubcategory.BannerPitchRound2ApprovedSeller,
    EnumNotificationCategory.Marketplace,
  ],
  [
    EnumNotificationSubcategory.BannerPitchRound2ApprovedBuyer,
    EnumNotificationCategory.Marketplace,
  ],
  [EnumNotificationSubcategory.BannerPitchRejectedManually, EnumNotificationCategory.Marketplace],
  [
    EnumNotificationSubcategory.BannerPitchRejectedAutomatically,
    EnumNotificationCategory.Marketplace,
  ],
  [EnumNotificationSubcategory.BannerPitchHired, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.BannerPitchDeletionRequested, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.BannerPitchDeleted, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.BannerCaseStudyApproved, EnumNotificationCategory.Content],
  [EnumNotificationSubcategory.BannerCaseStudyRejected, EnumNotificationCategory.Content],
  [EnumNotificationSubcategory.BannerCaseStudyEditRejected, EnumNotificationCategory.Content],
  [EnumNotificationSubcategory.BannerCaseStudyReviewed, EnumNotificationCategory.Content],
  [
    EnumNotificationSubcategory.BannerSellerProfileApproved,
    EnumNotificationCategory.PostRegistration,
  ],
  [
    EnumNotificationSubcategory.BannerSellerProfilePending,
    EnumNotificationCategory.PostRegistration,
  ],
  [
    EnumNotificationSubcategory.BannerSellerProfileRejected,
    EnumNotificationCategory.PostRegistration,
  ],
  [
    EnumNotificationSubcategory.BannerBuyerProfileApproved,
    EnumNotificationCategory.PostRegistration,
  ],
  [EnumNotificationSubcategory.BannerEventInvitePending, EnumNotificationCategory.Calendar],
  [EnumNotificationSubcategory.ReviewContract, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.ContractReady, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.ApproveAnnex, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.SubmittedAnnex, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.ReviewAnnex, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.AnnexReady, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.TerminateContract, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.CancelContract, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.PaymentDue, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.PaymentOverdue, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.CreateTask, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.DeleteTask, EnumNotificationCategory.E2EManagment],
  [EnumNotificationSubcategory.ApproveCaseStudy, EnumNotificationCategory.Content],
  [EnumNotificationSubcategory.RejectCaseStudy, EnumNotificationCategory.Content],
  [EnumNotificationSubcategory.ApproveCaseStudyEdit, EnumNotificationCategory.Content],
  [EnumNotificationSubcategory.RejectCaseStudyEdit, EnumNotificationCategory.Content],
  [EnumNotificationSubcategory.CaseStudyReviewLive, EnumNotificationCategory.Content],
  [EnumNotificationSubcategory.CaseStudyRequestReview, EnumNotificationCategory.Content],
  [EnumNotificationSubcategory.ApproveTimedProject, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.RejectTimedProject, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.NewTimedProject, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.ApproveTimedProjectEditBuyer, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.ApproveTimedProjectEditSeller, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.RejectTimedProjectEdit, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.TimedProjectDeadlinePassed, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.DeleteTimedProjectBuyer, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.DeleteTimedProjectSeller, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.DeleteTimedProjectCanceled, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.PitchSubmittedSeller, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.PitchSubmittedBuyer, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.PitchRejected, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.PitchEditAcceptedSeller, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.PitchEditAcceptedBuyer, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.PitchDeletedSeller, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.PitchDeletedBuyer, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.PitchRound2Accepted, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.PitchRound1Rejected, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.PitchHired, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.PitchRound2Rejected, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.PitchAutomaticallyRejected, EnumNotificationCategory.Marketplace],
  [EnumNotificationSubcategory.DeletePitchCanceled, EnumNotificationCategory.Marketplace],
]);

export enum EnumOrderByOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export enum EnumServiceProviderScope {
  Local = 'local',
  Global = 'global',
  Undefined = 'undefined',
}

export enum EnumTimedProjectStatus {
  Active = 'active',
  Closed = 'closed',
  Draft = 'draft',
  Pending = 'pending',
  Archived = 'archived',
  Canceled = 'canceled',
}

export enum EnumTimedProjectPitchType {
  Form = 'form',
  Video = 'video',
}

export enum EnumTimedProjectPitchStatus {
  Draft = 'draft',
  Pending = 'pending',
  FirstRound = 'first_round',
  SecondRound = 'second_round',
  FirstRoundRejected = 'first_round_rejected',
  SecondRoundRejected = 'second_round_rejected',
  Chosen = 'chosen',
  FirstRoundWithdrawn = 'first_round_withdrawn',
  SecondRoundWithdrawn = 'second_round_withdrawn',
  Hired = 'hired',
  Archived = 'archived',
  Canceled = 'canceled',
}

export enum EnumTimedProjectPitchRejectFeedbackScope {
  Overall = 'overall',
  Knowledge = 'knowledge',
  Strategy = 'strategy',
  Budget = 'budget',
}

export enum EnumCaseStudyStatus {
  Draft = 'draft',
  Pending = 'pending',
  Published = 'published',
  Archived = 'archived',
  UnderReview = 'under_review',
  Verified = 'verified',
}

export enum EnumContactRequestType {
  Information = 'information',
  FeatureRequest = 'feature-request',
  Problem = 'problem',
  JoinRequest = 'join-request',
  Other = 'other',
}

export const mapContactRequestType = new Map([
  [EnumContactRequestType.Information, 'Information'],
  [EnumContactRequestType.FeatureRequest, 'Feature Request'],
  [EnumContactRequestType.Problem, 'Problem'],
  [EnumContactRequestType.JoinRequest, 'Join Request'],
  [EnumContactRequestType.Other, 'Other'],
]);

export enum ChatChannelType {
  Public = 'public',
  Direct = 'direct',
}

export enum FooterType {
  SellerArchive = 'seller_archive',
  CaseStudyArchive = 'case_study_archive',
}

export enum EnumSettingsType {
  BitrixAssignedBy = 'bitrix_assigned_by',
  SponaMediaContactRecipients = 'spona_media_contact_recipients',
  ContactUsRecipients = 'contact_us_recipients',
}

export enum EnumBlogStatus {
  Draft = 'draft',
  Published = 'published',
}
export const mapBlogStatus = new Map([
  [EnumBlogStatus.Published, 'Published'],
  [EnumBlogStatus.Draft, 'Draft'],
]);

export enum EnumEmailLogType {
  Verification = 'email_verification',
  PasswordReset = 'password_reset',
  NewUserRegistred = 'new_user_registred',
  ConfirmAccount = 'confirm_account',
  CaseStudyReviewRequest = 'case_study_review_request',
}

export enum EnumBlogFeaturedPosition {
  One = 'one',
  Two = 'two',
  Three = 'three',
}

export enum EnumProjectRequestStatus {
  Requested = 'requested',
  ProjectCreated = 'project_created',
  Closed = 'closed',
  Deleted = 'deleted',
}

export const mapProjectRequestStatus = new Map([
  [EnumProjectRequestStatus.Requested, 'Requested'],
  [EnumProjectRequestStatus.ProjectCreated, 'Project Created'],
  [EnumProjectRequestStatus.Closed, 'Closed'],
  [EnumProjectRequestStatus.Deleted, 'Deleted'],
]);

export enum EnumPriceLookup {
  BasicMonthly = 'basic_monthly',
  BasicYearly = 'basic_yearly',
  StandardMonthly = 'standard_monthly',
  StandardYearly = 'standard_yearly',
  PremiumMonthly = 'premium_monthly',
  PremiumYearly = 'premium_yearly',
}
