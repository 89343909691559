import { Typography, Stack } from '@mui/material';
import { CompanySubscriptionInfo } from 'tdc-web-backend/companies/schemas';
import useAuth from '../../../../../utils/hooks/useAuth';
import useGetOne from '../../../../../utils/hooks/crud-hooks/useGetOne';
import LoadingIndicator from '../../../../../components/loading-indicator/LoadingIndicator';
import NoActiveSubscription from './components/NoActiveSubscription';
import ActiveSubscription from './components/ActiveSubscription';

interface BillingProps {
  // Add your props here if needed
}

const Billing = () => {
  const {
    authData: { userData },
  } = useAuth();

  const { data: subscriptionInfoResponse, isLoading: isGetDataLoading } =
    useGetOne<CompanySubscriptionInfo>({
      resource: `companies/${userData?.membership?.company}/active-subscription`,
      enabled: !!userData?.membership?.company,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
    });
  const subscriptionInfo = subscriptionInfoResponse?.data;

  return (
    <Stack spacing={5}>
      <Typography variant="heading3" color="primaryDark.500">
        your subscription
      </Typography>
      {isGetDataLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {subscriptionInfo?.activeSubscription ? (
            <ActiveSubscription subscriptionInfo={subscriptionInfo} />
          ) : (
            <NoActiveSubscription />
          )}
        </>
      )}
    </Stack>
  );
};

export default Billing;
