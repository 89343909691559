import { IconButton, Stack, Typography, Divider, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MilestoneDto, MilestoneSnakeDto } from 'tdc-web-backend/milestones/schemas';
import { CompanyRole, EnumProjectStatus, EnumProjectTaskStatus } from 'tdc-web-backend/enums/enums';
import { TaskDto } from 'tdc-web-backend/tasks/schemas';
import { SubtaskDto } from 'tdc-web-backend/subtasks/schemas';
import { ProjectDto } from 'tdc-web-backend/projects/schemas';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { ReactComponent as AddBookmark } from '../../../../assets/icons/layout-icons/AddBookmark.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/icons/layout-icons/ArrowLeftShortFill.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/layout-icons/TrashIcon.svg';
import { ReactComponent as FolderPlusIcon } from '../../../../assets/icons/layout-icons/FolderPlusIcon.svg';
import { EndToEndDetailScreenTypeEnum, FlagTypeEnum } from '../../../../utils/enums';
import FlagAndUnflagModal from '../../../../components/flag-modal-and-unflag-modal/FlagAndUnflagModal';
import useGetOne from '../../../../utils/hooks/crud-hooks/useGetOne';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import { useRefresh } from '../../../../utils/hooks/crud-hooks/useRefresh';
import useUserCompany from '../../../../utils/hooks/useUserCompany';
import SingleSlideCarousel, {
  RenderCarouselItemProps,
} from '../../../../components/single-slide-carousel/SingleSlideCarousel';
import CustomButton from '../../../../components/button/CustomButton';
import CustomModal from '../../../../components/modal/CustomModal';
import { secondaryBlue, secondaryPink } from '../../../../utils/color';
import useDeleteOne from '../../../../utils/hooks/crud-hooks/useDeleteOne';
import theme from '../../../../utils/theme';
import LeftColumn from './LeftColumn/LeftColumn';
import RightColumn from './RightColumn/RightColumn';
import CarouselItem from './CarouselItem/CarouselItem';
import useReferenceOne from '../../../../utils/hooks/crud-hooks/useReferenceOne';
import { DASHBOARD_WRAPPER_PADDING } from '../../../Dashboard/Main';
import CustomTooltip from '../../../../components/tooltip/CustomTooltip';

const CAROUSEL_ITEM_WIDTH = 370;

const determineWhichTaskToShow = (tasks: TaskDto[]): string | null => {
  let taskToShow: TaskDto | null = null;

  const tasksInProgress = tasks.filter((task) => task.status === EnumProjectTaskStatus.InProgress);

  const tasksPending = tasks.filter((task) => task.status === EnumProjectTaskStatus.Pending);

  const areAllTasksCompleted = tasks.every(
    (task) => task.status === EnumProjectTaskStatus.Completed,
  );

  if (areAllTasksCompleted) {
    taskToShow = tasks[0];
  }

  // in progress
  if (tasksInProgress.length > 0) {
    const oldestTaskInProgress = tasksInProgress.sort(
      (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime(),
    )[0];

    taskToShow = oldestTaskInProgress;
  }

  // pending
  if (tasksPending.length > 0 && tasksInProgress.length === 0) {
    taskToShow = tasksPending[0];
  }

  if (taskToShow) {
    return taskToShow.id;
  }

  return null;
};

const MilestoneDetailScreen = () => {
  const navigate = useNavigate();
  const params = useParams();

  const refresh = useRefresh();

  const userCompany = useUserCompany();
  const isUserBuyer = userCompany?.roles?.includes(CompanyRole.Buyer);

  // used for flagging and deleting modal to determine on which
  // entity flagging and deleting actions needs to be done
  const [entityType, setEntityType] = useState<EndToEndDetailScreenTypeEnum>(
    EndToEndDetailScreenTypeEnum.Milestone,
  );
  const [entityId, setEntityId] = useState<string | null>(null);

  // modal states
  const [isFlagAndUnflagModalOpen, setIsFlagAndUnflagModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isNewMilestoneModalOpen, setIsNewMilestoneModalOpen] = useState<boolean>(false);

  // other booleans
  const [showRightColumnn, setShowRightColumn] = useState<boolean>(true);
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);

  // task id of whose data to show on the right column
  const [taskId, setTaskId] = useState<string | null>(null);
  const [tasks, setTasks] = useState<TaskDto[] | null>(null);
  const [numberOfTasksRows, setNumberOfRowsTasks] = useState<number | null>(null);
  const [isTaskDateEndEditing, setIsTaskDateEndEditing] = useState<boolean>(false);

  const isLessThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const [rightColumnWidth, setRightColumnWidth] = useState<number>(0);

  // milestone related queries
  const { data: milestone } = useGetOne<MilestoneDto>({
    enabled: !!params?.milestoneId,
    resource: `milestones/${params.milestoneId}`,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const milestoneData = milestone?.data;

  const { data: milestones } = useGetMany<MilestoneSnakeDto>({
    resource: `milestones/snake?project=${milestone?.data.project}`,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const milestonesArray = milestones?.data.results;

  const { data: relatedMilestones } = useGetMany<MilestoneDto>({
    enabled: !!params?.milestoneId,
    resource: `/milestones/?related=${params?.milestoneId}`,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const recurringMilestones: MilestoneDto[] | undefined = relatedMilestones?.data.results;

  // project related query
  const { data: projectData } = useReferenceOne<ProjectDto>({
    enabled: !!milestoneData?.project,
    resource: 'projects',
    id: milestoneData?.project,
  });
  //

  // task & subtask related queries
  const {
    data: task,
    isLoading: isFetchingTaskLoading,
    refetch: refetchTask,
  } = useGetOne<TaskDto>({
    enabled: !!taskId,
    resource: `/tasks/${taskId}`,
  });

  const taskData = task?.data;

  const { data: subtask } = useGetOne<SubtaskDto>({
    enabled: !!entityId,
    resource: `/subtasks/${entityId}`,
  });

  const subtaskData = subtask?.data;

  const { mutate: deleteTask } = useDeleteOne<TaskDto>({ resource: '/tasks' });
  const { mutate: deleteSubtask } = useDeleteOne<SubtaskDto>({ resource: '/subtasks' });
  //

  const getRecurringMilestonesParentIndex = (): number | null => {
    // variable idx here is used to store an index of a milestone (parent) that
    // has a relatedMilestone's id in the query param;
    // in other words, for a relatedMilestone, store at which index that
    // relatedMilestone appears but looking at the "/milestone/snake" response array;
    // scenario (problem) which it solves: setting the correct parent's milestone
    // index based on child's (relatedMilestone) id, so that it switches to that
    // correct (parent's) index in the carousel
    let idx = null;

    milestonesArray?.forEach((milestone, index, array) => {
      if (milestone.relatedMilestones.length > 0) {
        milestone.relatedMilestones.forEach((relatedMilestone: MilestoneDto) => {
          if (relatedMilestone.id === params.milestoneId) {
            idx = array.findIndex((item) => item.id === relatedMilestone.parent);
          }
        });
      }
    });

    if (idx !== null && idx !== undefined) {
      return idx;
    }

    return null;
  };
  //

  const onDeleteTask = (taskId: string) => {
    deleteTask(
      { id: taskId as string },
      {
        onSuccess: () => {
          if (isDeleteModalOpen) {
            setIsDeleteModalOpen(false);
          }

          refresh();
        },
      },
    );
  };

  const onDeleteSubtask = (subtaskId: string) => {
    deleteSubtask(
      { id: subtaskId as string },
      {
        onSuccess: () => {
          if (isDeleteModalOpen) {
            setIsDeleteModalOpen(false);
          }

          refresh();
          refetchTask();
        },
      },
    );
  };

  return (
    <>
      <FlagAndUnflagModal
        entityId={entityId as string}
        entityType={entityType}
        actionType={(() => {
          switch (entityType) {
            case EndToEndDetailScreenTypeEnum.Milestone: {
              // if user is buyer
              if (isUserBuyer) {
                // if buyer flagged a milestone, action will be to unflag it
                if (milestoneData?.buyerFlagged) return FlagTypeEnum.Unflag;

                // if buyer did not flag a milestone, action will be to flag it
                return FlagTypeEnum.Flag;
              }

              // if user is seller
              // if seller flagged a milestone, action will be to unflag it
              if (milestoneData?.sellerFlagged) return FlagTypeEnum.Unflag;

              // if seller did not flag a milestone, action will be to flag it
              return FlagTypeEnum.Flag;
            }
            case EndToEndDetailScreenTypeEnum.Task: {
              if (isUserBuyer) {
                if (taskData?.buyerFlagged) return FlagTypeEnum.Unflag;

                return FlagTypeEnum.Flag;
              }

              if (taskData?.sellerFlagged) return FlagTypeEnum.Unflag;

              return FlagTypeEnum.Flag;
            }
            case EndToEndDetailScreenTypeEnum.Subtask: {
              if (isUserBuyer) {
                if (subtaskData?.buyerFlagged) return FlagTypeEnum.Unflag;

                return FlagTypeEnum.Flag;
              }

              if (subtaskData?.sellerFlagged) return FlagTypeEnum.Unflag;

              return FlagTypeEnum.Flag;
            }

            default:
              return FlagTypeEnum.Flag;
          }
        })()}
        isModalOpen={isFlagAndUnflagModalOpen}
        setIsModalOpen={setIsFlagAndUnflagModalOpen}
      />

      {/* delete modal */}
      <CustomModal open={isDeleteModalOpen} width="35%">
        {/* icon, title & divider */}
        <Stack spacing={3}>
          <Stack spacing={2} direction="row" alignItems="center">
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                bgcolor: 'secondaryPink.100',
                width: 'fit-content',
                height: 'fit-content',
                borderRadius: '6px',
                px: 2,
                py: 1,
              }}
            >
              <TrashIcon fill={secondaryPink[700]} style={{ width: 18, height: 18 }} />
            </Stack>

            <Typography variant="heading3" color="primaryDark.600">
              {`Delete ${entityType}`}
            </Typography>
          </Stack>

          <Divider sx={{ borderColor: '#E5E8FF' }} />

          <Typography color="primaryDark.500">{`Are you sure you want to delete the ${entityType}?`}</Typography>

          <Stack spacing={2.5}>
            <Divider sx={{ borderColor: '#E5E8FF' }} />

            <Stack sx={{ justifyContent: 'end' }} spacing={2} direction="row">
              <CustomButton
                variant="secondary"
                // reset here is to clear the summary input
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </CustomButton>

              <CustomButton
                variant="primary"
                onClick={() => {
                  entityType === EndToEndDetailScreenTypeEnum.Task
                    ? onDeleteTask(taskData?.id as string)
                    : onDeleteSubtask(entityId as string);
                }}
              >
                Confirm
              </CustomButton>
            </Stack>
          </Stack>
        </Stack>
      </CustomModal>

      {/* new milestone modal */}
      <CustomModal open={isNewMilestoneModalOpen} width="30%">
        {/* icon, title & divider */}
        <Stack spacing={3}>
          <Stack spacing={2} direction="row" alignItems="center">
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                bgcolor: '#E5E8FF',
                borderRadius: '6px',
                px: 2.05,
                py: 2.05,
              }}
            >
              <FolderPlusIcon fill={secondaryBlue[700]} style={{ width: 18, height: 18 }} />
            </Stack>

            <Typography variant="heading3" color="primaryDark.600">
              New milestone
            </Typography>
          </Stack>

          <Divider sx={{ borderColor: '#E5E8FF' }} />

          <Typography color="primaryDark.500">
            New milestones must be added through a contract annex. Changes must be formally approved
            by both parties.
          </Typography>

          <Stack spacing={2.5}>
            <Divider sx={{ borderColor: '#E5E8FF' }} />

            <Stack sx={{ justifyContent: 'end' }} spacing={2} direction="row">
              <CustomButton variant="secondary" onClick={() => setIsNewMilestoneModalOpen(false)}>
                Cancel
              </CustomButton>

              <CustomButton
                variant="primary"
                onClick={() => {
                  navigate(
                    `../project/${milestoneData?.project}/contract/${projectData?.latestContract}`,
                  );
                }}
              >
                Continue
              </CustomButton>
            </Stack>
          </Stack>
        </Stack>
      </CustomModal>

      <Stack spacing={4} sx={{ width: '100%', height: '100%' }}>
        {/* back button and new milestone button */}
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack spacing={1.75} direction="row" alignItems="center">
            <IconButton
              onClick={() => navigate(`../project/${milestoneData?.project}`)}
              sx={{
                border: '1px solid',
                borderColor: 'primaryDark.300',
                width: '2.875rem',
                height: '2.875rem',
              }}
            >
              <ArrowLeftIcon />
            </IconButton>

            <Typography variant="heading4" color="primaryDark.500">
              Back
            </Typography>
          </Stack>
          {projectData?.locked && (
            <CustomTooltip
              title="You have exceeded the maximum number of open projects allowed under your current subscription plan."
              placement="top"
            >
              <LockOutlinedIcon sx={{ fontSize: '1.6rem', color: 'secondaryOrange.601' }} />
            </CustomTooltip>
          )}

          {projectData?.status !== EnumProjectStatus.Completed && (
            <CustomButton
              variant="primaryLight"
              className="react-joyride-step-1-milestone"
              onClick={() => setIsNewMilestoneModalOpen(true)}
              startIcon={<AddBookmark />}
              disabled={projectData?.locked}
              sx={{
                height: '3rem',
                width: '11.5rem',
                '& .MuiButton-startIcon': {
                  mr: 1.75,
                },
              }}
            >
              <Typography variant="body3" fontWeight={600}>
                new milestone
              </Typography>
            </CustomButton>
          )}
        </Stack>

        {/* milestones carousel */}
        {
          <Stack sx={{ position: 'absolute', mt: '72px !important' }}>
            <Typography
              variant="heading4"
              color="primaryDark.500"
              className="react-joyride-step-2-milestone"
            >
              milestones
            </Typography>

            <SingleSlideCarousel<MilestoneSnakeDto>
              carouselItemWidth={CAROUSEL_ITEM_WIDTH}
              sx={{
                mt: '10px !important',
                pb: '20px',
                ml: `-${DASHBOARD_WRAPPER_PADDING}px`,
                pr: `${DASHBOARD_WRAPPER_PADDING}px`,
                '&>*:nth-child(3)': {
                  marginLeft: '22px',
                },
              }}
              items={milestonesArray || []}
              // activeIndexItem prop is used for displaying a card in the
              // correct index (order) when for example copying and pasting the url in a new tab
              activeIndexItem={(() => {
                if (
                  getRecurringMilestonesParentIndex() === null ||
                  getRecurringMilestonesParentIndex() === undefined
                ) {
                  return milestonesArray?.findIndex(
                    (milestone) => milestone.id === params.milestoneId,
                  );
                }

                return getRecurringMilestonesParentIndex() as number;
              })()}
              renderCarouselItem={({ item, sx }: RenderCarouselItemProps<MilestoneSnakeDto>) => {
                if (item) {
                  return (
                    <CarouselItem
                      carouselItemWidth={CAROUSEL_ITEM_WIDTH}
                      item={item}
                      isMouseDown={isMouseDown}
                      setIsMouseDown={setIsMouseDown}
                      setTaskId={setTaskId}
                      determineWhichTaskToShow={determineWhichTaskToShow}
                      sx={sx}
                    />
                  );
                }

                return null;
              }}
            />
          </Stack>
        }

        <Divider
          sx={{
            position: 'absolute',
            borderColor: '#DCDCFF',
            left: 0,
            right: 0,
            top: 260,
            width: '100%',
          }}
        />

        {/* main row */}
        <Stack
          spacing={2.5}
          direction="row"
          sx={{ position: 'relative', mt: '265px !important', height: '100%' }}
        >
          {/* left column */}
          <LeftColumn
            showRightColumnn={showRightColumnn}
            setShowRightColumn={setShowRightColumn}
            milestoneData={milestoneData}
            milestonesArray={milestonesArray}
            getRecurringMilestonesParentIndex={getRecurringMilestonesParentIndex}
            setIsFlagAndUnflagModalOpen={setIsFlagAndUnflagModalOpen}
            setEntityType={setEntityType}
            setEntityId={setEntityId}
            setNumberOfRowsTasks={setNumberOfRowsTasks}
            tasks={tasks}
            setTasks={setTasks}
            taskId={taskId}
            setTaskId={setTaskId}
            scoreMilestone={(() => {
              if (milestoneData) {
                return (
                  (milestoneData.tasksCompletedCount /
                    (milestoneData.tasksCount - milestoneData.tasksCanceledCount)) *
                  100
                );
              }

              return 0;
            })()}
            determineWhichTaskToShow={determineWhichTaskToShow}
            recurringMilestones={recurringMilestones}
            setIsTaskDateEndEditing={setIsTaskDateEndEditing}
            rightColumnWidth={rightColumnWidth}
            isProjectLocked={projectData?.locked}
          />

          {/* right column */}
          <RightColumn
            numberOfTasksRows={numberOfTasksRows}
            showRightColumnn={showRightColumnn}
            isLessThanLarge={isLessThanLarge}
            milestoneData={milestoneData}
            taskData={taskData as TaskDto}
            isFetchingTaskLoading={isFetchingTaskLoading}
            isTaskDateEndEditing={isTaskDateEndEditing}
            setIsTaskDateEndEditing={setIsTaskDateEndEditing}
            setShowRightColumn={setShowRightColumn}
            setIsFlagAndUnflagModalOpen={setIsFlagAndUnflagModalOpen}
            setEntityType={setEntityType}
            setEntityId={setEntityId}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            task={task}
            getRightColumnRefWidth={(width) => setRightColumnWidth(width)}
            isProjectLocked={projectData?.locked}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default MilestoneDetailScreen;
