import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import CustomInput from '../../../../components/custom-input/CustomInput';
import CustomButton from '../../../../components/button/CustomButton';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/button-icons/PlusIcon-Seller-EditModal.svg';
import useCreate from '../../../../utils/hooks/crud-hooks/useCreate';

const AdminSubAddPrice = () => {
  const navigate = useNavigate();
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      sId: '',
      name: '',
      sLookupKey: '',
      lookupKey: '',
      active: true,
      product: '',
      interval: '',
    },
  });

  const { mutate } = useCreate({
    resource: 'subscription-prices',
  });

  const onSubmit = (data: any) => {
    mutate(
      {
        ...data,
      },
      {
        onSuccess: () => {
          navigate('../subscription-prices');
        },
      },
    );
  };

  return (
    <FormProvider {...formMethods}>
      <form>
        <Stack maxWidth="600px" spacing={4}>
          <CustomInput
            name="sId"
            input={<CustomInput.Text />}
            label="sId"
            sx={{ minHeight: 'none' }}
            required
          />

          <CustomInput
            name="sLookupKey"
            input={<CustomInput.Text />}
            label="sLookupKey"
            sx={{ minHeight: 'none' }}
            required
          />
          <CustomInput
            name="lookupKey"
            input={<CustomInput.Text />}
            label="Lookup key"
            sx={{ minHeight: 'none' }}
            required
          />
          <CustomInput
            name="name"
            input={<CustomInput.Text />}
            label="Name"
            sx={{ minHeight: 'none' }}
            required
          />

          <CustomInput
            name="product"
            input={<CustomInput.Text />}
            label="Product sId"
            sx={{ minHeight: 'none' }}
            required
          />

          <CustomInput
            name="interval"
            input={<CustomInput.Text />}
            label="Interval"
            sx={{ minHeight: 'none' }}
            required
          />
          <CustomInput
            name="active"
            input={<CustomInput.Checkbox />}
            label="Active"
            sx={{ minHeight: 'none' }}
          />
          <Stack direction="row">
            <CustomButton
              variant="primaryLight"
              sx={{
                boxShadow: 'none',
                alignSelf: 'flex-end',
                mt: '10px',
              }}
              onClick={formMethods.handleSubmit(onSubmit)}
              startIcon={<PlusIcon />}
            >
              Add Price
            </CustomButton>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default AdminSubAddPrice;
