import { CompanySubscriptionInfo } from 'tdc-web-backend/companies/schemas';
import { Stack, Typography } from '@mui/material';
import DateDisplayCard from '../../../../../../components/DateDisplayCard/DateDisplayCard';
import CustomButton from '../../../../../../components/button/CustomButton';
import useAuth from '../../../../../../utils/hooks/useAuth';
import useCreate from '../../../../../../utils/hooks/crud-hooks/useCreate';
import CustomChip from '../../../../../../components/custom-chip/CustomChip';

interface ActiveSubscriptionProps {
  subscriptionInfo: CompanySubscriptionInfo;
}

const ActiveSubscription = ({ subscriptionInfo }: ActiveSubscriptionProps) => {
  const {
    authData: { userData },
  } = useAuth();

  const isPaymentAdmin = userData?.membership?.isPaymentAdministrator;

  const { mutate: createPortalSession, isLoading } = useCreate<
    { url: string },
    { return_url: string }
  >({
    resource: `companies/portal-url`,
  });

  const handleManageSubscription = () => {
    createPortalSession(
      { return_url: 'dashboard' },
      {
        onSuccess: (data) => {
          window.location.href = data.data.url;
        },
      },
    );
  };

  return (
    <Stack direction="row" justifyContent="space-between" spacing={4}>
      <Stack spacing={2}>
        <Typography variant="body3" color="primaryDark.500">
          {`Your membership plan subscription is automatically billed every ${subscriptionInfo.activeSubscription?.price.interval}.`}
        </Typography>
        <Stack direction="row" spacing={5} alignItems="center">
          <Typography variant="headline4" color="primaryDark.500">
            {subscriptionInfo.activeSubscription?.price.product.name} plan
          </Typography>
          {isPaymentAdmin && (
            <CustomButton
              onClick={() => handleManageSubscription()}
              loading={isLoading}
              variant="secondaryText"
            >
              Manage subscription
            </CustomButton>
          )}
        </Stack>
      </Stack>
      <Stack direction="row" spacing={4} alignItems="center">
        {subscriptionInfo.activeSubscription?.sStatus === 'trialing' && (
          <CustomChip
            label="Free trial"
            sx={{
              bgcolor: 'secondaryBlue.100',
              color: 'secondaryBlue.700',
              border: '1px solid',
              borderColor: 'secondaryBlue.100',
            }}
          />
        )}
        <DateDisplayCard
          text="next automatic renewal"
          date={new Date(subscriptionInfo.activeSubscription?.sCurrentPeriodEnd || '')}
          sx={{ width: '255px' }}
        />
      </Stack>
    </Stack>
  );
};

export default ActiveSubscription;
