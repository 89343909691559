import { CompanyRole } from 'tdc-web-backend/enums/enums';
import { Stack, Typography } from '@mui/material';
import useUserCompany from '../../../../../../utils/hooks/useUserCompany';
import Link from '../../../../../../components/link/Link';

const NoActiveSubscription = () => {
  const userCompany = useUserCompany();
  const isUserBuyer = userCompany?.roles?.includes(CompanyRole.Buyer);
  const linkTo = isUserBuyer ? '/how-to-manage-buyer/pricing' : '/how-to-manage-seller/pricing';
  return (
    <Stack spacing={4}>
      <Typography variant="body3" color="primaryDark.500">
        You have no active subscriptions.
      </Typography>
      <Link to={linkTo}>
        <Typography
          variant="body1"
          color="primaryLight.500"
          sx={{ textDecoration: 'underline', fontFamily: 'Gellix-Regular', fontSize: '1.25rem' }}
        >
          Pricing
        </Typography>
      </Link>
    </Stack>
  );
};

export default NoActiveSubscription;
