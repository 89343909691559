import { IconButton, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  SubscriptionPriceDto,
  UpdateSubscriptionPriceDto,
} from 'tdc-web-backend/subscription-prices/schemas';
import { FormProvider, useForm } from 'react-hook-form';
import CustomInput from '../../../../components/custom-input/CustomInput';
import CustomButton from '../../../../components/button/CustomButton';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/button-icons/PlusIcon-Seller-EditModal.svg';
import useUpdate from '../../../../utils/hooks/crud-hooks/useUpdate';
import useGetOne from '../../../../utils/hooks/crud-hooks/useGetOne';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/chat-icons/trash2-fill.svg';
import { primaryDark, secondaryPink } from '../../../../utils/color';
import useDeleteOne from '../../../../utils/hooks/crud-hooks/useDeleteOne';
import BackButton from '../../../../components/button/BackButton';

const AdminSubAddPrice = () => {
  const navigate = useNavigate();
  const { sId } = useParams();

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      sLookupKey: '',
      lookupKey: '',
      active: false,
      product: '',
      interval: '',
    },
  });

  const { data } = useGetOne<SubscriptionPriceDto>({
    resource: `subscription-prices/${sId}`,
    enabled: !!sId,
    onSuccess: (data) => {
      formMethods.reset({
        name: data.data.name,
        sLookupKey: data.data.sLookupKey,
        lookupKey: data.data.lookupKey,
        product: data.data.product,
        interval: data.data.interval,
        active: data.data.active,
      });
    },
  });

  const { mutate } = useUpdate<SubscriptionPriceDto, UpdateSubscriptionPriceDto>({
    resource: 'subscription-prices',
  });

  const onSubmit = (data: any) => {
    if (!sId) return;
    mutate(
      {
        id: sId,
        data: { ...data },
      },
      {
        onSuccess: () => {
          navigate('../subscription-prices');
        },
      },
    );
  };

  const { mutate: deletePrice } = useDeleteOne({ resource: 'subscription-prices' });

  return (
    <FormProvider {...formMethods}>
      <form>
        <Stack maxWidth="600px" spacing={4}>
          <BackButton onClick={() => navigate('..', { relative: 'path' })} />
          <CustomInput
            name="sLookupKey"
            input={<CustomInput.Text />}
            label="sLookupKey"
            sx={{ minHeight: 'none' }}
            required
          />
          <CustomInput
            name="lookupKey"
            input={<CustomInput.Text />}
            label="Lookup key"
            sx={{ minHeight: 'none' }}
            required
          />
          <CustomInput
            name="name"
            input={<CustomInput.Text />}
            label="Name"
            sx={{ minHeight: 'none' }}
            required
          />

          <CustomInput
            name="product"
            input={<CustomInput.Text />}
            label="Product sId"
            sx={{ minHeight: 'none' }}
            required
          />

          <CustomInput
            name="interval"
            input={<CustomInput.Text />}
            label="Interval"
            sx={{ minHeight: 'none' }}
            required
          />

          <CustomInput
            name="active"
            input={<CustomInput.Checkbox />}
            label="Active"
            sx={{ minHeight: 'none' }}
          />
          <Stack direction="row" justifyContent="space-between">
            <CustomButton
              variant="primaryLight"
              sx={{
                boxShadow: 'none',
                alignSelf: 'flex-end',
                mt: '10px',
              }}
              onClick={formMethods.handleSubmit(onSubmit)}
              startIcon={<PlusIcon />}
            >
              Save changes
            </CustomButton>

            <CustomButton
              variant="primaryLight"
              sx={{
                boxShadow: 'none',
                alignSelf: 'flex-end',
                mt: '10px',
                color: secondaryPink[500],
                borderColor: secondaryPink[500],
              }}
              onClick={() => {
                if (!sId) return;
                deletePrice({ id: sId });
                navigate('../subscription-prices');
              }}
              startIcon={<TrashIcon fill={secondaryPink[500]} style={{ width: 22, height: 22 }} />}
            >
              Delete price
            </CustomButton>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default AdminSubAddPrice;
