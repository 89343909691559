import { useState } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { FormProvider, useForm } from 'react-hook-form';
import { CompanyRole } from 'tdc-web-backend/enums/enums';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import ControlledTextInput from '../../../../../components/custom-inputs/ControlledTextInput';
import ControlledReferenceSelectInput from '../../../../../components/custom-inputs/ControlledReferenceSelectInput';
import useGetMany from '../../../../../utils/hooks/crud-hooks/useGetMany';
import BaseInputLabel from '../../../../../components/custom-inputs/BaseInputLabel';
import LoadingButton from '../../../../../components/button/LoadingButton';
import useUpdate from '../../../../../utils/hooks/crud-hooks/useUpdate';
import ConfirmModal from '../../../../../components/confirm-modal/ConfirmModal';
import { secondaryPink } from '../../../../../utils/color';
import { ReactComponent as ErrorAlertIcon } from '../../../../../assets/icons/layout-icons/ErrorAlertIcon.svg';

const CreateProject = ({ projectRequestData }: { projectRequestData: any }) => {
  const navigate = useNavigate();
  const [isErrModalOpen, setIsErrModalOpen] = useState(false);
  const [errModalMsg, setErrModalMsg] = useState('');

  const isBuyer = projectRequestData?.company?.roles.includes(CompanyRole.Buyer);
  const formMethods = useForm({
    defaultValues: {
      name: projectRequestData.projectName,
      description: projectRequestData.projectDescription,
      buyer: '',
      seller: '',
    },
  });
  const { data: sellerData } = useGetMany<CompanyDto>({
    resource: '/companies?role=seller_tool&role=seller_service&status=approved',
    enabled: !!projectRequestData && isBuyer,
  });
  const { data: buyerData } = useGetMany<CompanyDto>({
    resource: '/companies?role=buyer&status=approved',
    enabled: !!projectRequestData && !isBuyer,
  });

  const { mutate, isLoading } = useUpdate({ resource: `project-requests` });

  const { handleSubmit } = formMethods;

  const submitForm = (data: any) => {
    const submitData = {
      participantCompany: isBuyer
        ? formMethods.getValues('seller')
        : formMethods.getValues('buyer'),
      name: formMethods.getValues('name'),
      description: formMethods.getValues('description'),
    };

    mutate(
      {
        id: projectRequestData.id,
        data: submitData,
        suffix: 'create-project',
      },
      {
        onSuccess: (data) => {
          navigate(`../projects/${(data?.data as any).id as string}`);
        },
        onError: (err) => {
          setErrModalMsg((err as AxiosError).response?.data.message || 'Something went wrong');
          setIsErrModalOpen(true);
        },
      },
    );
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(submitForm)}>
          <Stack spacing={1} sx={{ mt: '3rem' }}>
            <Stack spacing={3}>
              <Stack spacing={1}>
                <Box sx={{ mb: 0 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    Title
                  </Typography>
                  <Typography variant="caption">
                    The title should include the necessery keywords and be between 4-9 words long.
                  </Typography>
                </Box>
                <ControlledTextInput name="name" placeholder="Title" required />
              </Stack>
              <Stack spacing={1}>
                <Box sx={{ mb: 0 }}>
                  <BaseInputLabel text="Buyer:" sx={{ fontSize: '14px !important', mb: 0 }} />
                </Box>

                {!isBuyer && (
                  <ControlledReferenceSelectInput
                    displayLabel={false}
                    name="buyer"
                    choices={buyerData?.data.results}
                    displayName="name"
                    displayValue="id"
                    required
                  />
                )}

                {isBuyer && (
                  <Typography variant="body1">{projectRequestData.company.name}</Typography>
                )}
              </Stack>
              <Stack spacing={1}>
                <Box sx={{ mb: 0 }}>
                  <BaseInputLabel text="Seller:" sx={{ fontSize: '14px !important', mb: 0 }} />
                </Box>

                {isBuyer && (
                  <ControlledReferenceSelectInput
                    displayLabel={false}
                    name="seller"
                    choices={sellerData?.data.results}
                    displayName="name"
                    displayValue="id"
                    required
                  />
                )}

                {!isBuyer && (
                  <Typography variant="body1">{projectRequestData.company.name}</Typography>
                )}
              </Stack>
              <Stack spacing={1}>
                <Box sx={{ mb: 0 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    Description
                  </Typography>
                </Box>
                <ControlledTextInput name="description" multiline minRows={4} required />
              </Stack>
            </Stack>
            <Divider sx={{ pt: 5 }} />

            <LoadingButton
              onButtonClick={() => null}
              sx={{ alignSelf: 'end' }}
              variant="contained"
              size="medium"
              fontWeight="bold"
              minWidth={102}
              type="submit"
              loading={isLoading}
            >
              Create project
            </LoadingButton>
          </Stack>
        </form>
      </FormProvider>
      <ConfirmModal
        isModalOpen={isErrModalOpen}
        setIsModalOpen={setIsErrModalOpen}
        title="Error"
        message={
          <Typography color="primaryDark.500" lineHeight="21px">
            {errModalMsg}
          </Typography>
        }
        confirmButtonText="Close"
        onConfirm={() => navigate('../project-requests')}
        iconProps={{
          icon: <ErrorAlertIcon fill={secondaryPink[700]} style={{ width: 24, height: 24 }} />,
          wrapperBackgroundColor: secondaryPink[100],
        }}
        showCancelButton={false}
      />
    </>
  );
};

export default CreateProject;
