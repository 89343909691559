import PricingMainLayout from '../components/PricingMainLayout/PricingMainLayout';
import SellerPricingContent from './components/SellerPricingContent';

const SellerPricing = () => (
  <PricingMainLayout>
    <SellerPricingContent />
  </PricingMainLayout>
);

export default SellerPricing;
