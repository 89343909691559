import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import CustomInput from '../../../../components/custom-input/CustomInput';
import CustomButton from '../../../../components/button/CustomButton';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/button-icons/PlusIcon-Seller-EditModal.svg';
import useCreate from '../../../../utils/hooks/crud-hooks/useCreate';

const AdminSubAddProduct = () => {
  const navigate = useNavigate();
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      sId: '',
      name: '',
      maxProjects: 0,
      active: true,
    },
  });

  const { mutate } = useCreate({
    resource: 'subscription-products',
  });

  const onSubmit = (data: {
    sId: string;
    name: string;
    maxProjects: string | number;
    active: boolean;
  }) => {
    mutate(
      {
        ...data,
        maxProjects: parseInt(data.maxProjects as string, 10),
      },
      {
        onSuccess: () => {
          navigate('../subscription-products');
        },
      },
    );
  };

  return (
    <FormProvider {...formMethods}>
      <form>
        <Stack maxWidth="600px" spacing={4}>
          <CustomInput
            name="sId"
            input={<CustomInput.Text />}
            label="sId"
            sx={{ minHeight: 'none' }}
            required
          />
          <CustomInput
            name="name"
            input={<CustomInput.Text />}
            label="Name"
            sx={{ minHeight: 'none' }}
            required
          />
          <CustomInput
            name="maxProjects"
            input={<CustomInput.Text />}
            label="Max projects"
            sx={{ minHeight: 'none' }}
            required
          />
          <CustomInput
            name="active"
            input={<CustomInput.Checkbox />}
            label="Active"
            sx={{ minHeight: 'none' }}
          />

          <Stack direction="row">
            <CustomButton
              variant="primaryLight"
              sx={{
                boxShadow: 'none',
                alignSelf: 'flex-end',
                mt: '10px',
              }}
              onClick={formMethods.handleSubmit(onSubmit)}
              startIcon={<PlusIcon />}
            >
              Add Product
            </CustomButton>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default AdminSubAddProduct;
