import { GridColDef } from '@mui/x-data-grid';
import useTable from '../../../../components/table/useTable';
import { useNavigate } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import BaseTable from '../../../../components/table/BaseTable';
import CustomButton from '../../../../components/button/CustomButton';

const AdminSubscriptionPrices = () => {
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      field: 'sId',
      headerName: 'sId',
      editable: false,
      flex: 0.25,
      // renderCell: (params: GridValueGetterParams) => (
      //   <Typography
      //     sx={{
      //       fontSize: '0.875rem',
      //       fontWeight: 600,
      //     }}
      //   >
      //     {params.row.name}
      //   </Typography>
      // ),
    },
    {
      field: 'sLookupKey',
      headerName: 'sLookupKey',
      editable: false,
      flex: 0.1,
    },
    {
      field: 'lookupKey',
      headerName: 'Lookup key',
      editable: false,
      flex: 0.1,
    },
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      flex: 0.2,
    },
    {
      field: 'active',
      headerName: 'Active',
      editable: false,
      flex: 0.05,
      type: 'boolean',
    },
    {
      field: 'product',
      headerName: 'Product sId',
      editable: false,
      flex: 0.25,
    },
    {
      field: 'interval',
      headerName: 'Interval',
      editable: false,
      flex: 0.05,
    },
  ];

  const { resourceLimit, ...props } = useTable({
    resourceUrl: 'subscription-prices',
  });

  return (
    <Box sx={{ width: '100%' }}>
      <Stack direction="column" spacing={1}>
        <BaseTable
          numberOfRowsPerPage={resourceLimit}
          columns={columns}
          onRowClick={(params) => navigate(`${params.id}`)}
          withElements={{
            withTitle: 'Subscription Prices',
            withCta: <CustomButton onClick={() => navigate('./add')}>Add price</CustomButton>,
          }}
          getRowId={(row) => row.sId}
          {...props}
        />
      </Stack>
    </Box>
  );
};

export default AdminSubscriptionPrices;
