import { Stack, StackProps, SxProps, Typography } from '@mui/material';
import CheckedItem from './components/CheckedItem';
import CustomButton, { CustomButtonProps } from '../../../../../components/button/CustomButton';
import { IPlanDetails } from '../../interface';
import useAuth from '../../../../../utils/hooks/useAuth';

interface IPlanDetailsProps {
  data: IPlanDetails;
  freeTrial?: boolean;
  freeTrialText?: string;
  mainStackProps?: StackProps;
  itemsStackProps?: StackProps;
  buttonProps?: CustomButtonProps & { text?: string };
}

const PlanDetails = ({
  data,
  freeTrial = true,
  freeTrialText = `Try for free for 30 days`,
  mainStackProps,
  itemsStackProps,
  buttonProps,
}: IPlanDetailsProps) => (
  <Stack gap={0.5} alignItems="center" width="100%" {...mainStackProps}>
    <Typography variant="publicHeading4" color="primaryLight.500" sx={{ mb: 1 }}>
      {data.name}
    </Typography>
    {data.pricing && (
      <>
        <Typography
          variant="publicBody1"
          color="primaryDark.900"
          display="flex"
          justifyContent="center"
          alignItems="center"
          align="center"
          gap={1}
        >
          {data.pricing.monthlyPrice}
          <Typography component="span" variant="publicBody5Light" color="primaryDark.900">
            monthly
          </Typography>
        </Typography>
        <Typography
          variant="publicBody5Light"
          color="primaryDark.900"
          display="flex"
          justifyContent="center"
          alignItems="center"
          align="center"
          gap={1}
        >
          or
          <Typography
            component="span"
            variant="publicBody5Light"
            color="primaryDark.900"
            sx={{ fontFamily: 'Gellix-SemiBold', fontWeight: 700 }}
          >
            {data.pricing.annualPrice} per month
          </Typography>
          annually
        </Typography>
        <Typography
          variant="publicBody5Light"
          color="primaryDark.900"
          display="flex"
          justifyContent="center"
          alignItems="center"
          align="center"
          gap={1}
        >
          taxes not included
        </Typography>
      </>
    )}
    {freeTrial && (
      <Typography variant="publicBody3" color="primaryDark.900" my={2}>
        {freeTrialText}
      </Typography>
    )}

    {data.subtitle && (
      <Typography variant="publicBody4" color="primaryDark.900" my={1}>
        {data.subtitle}
      </Typography>
    )}
    <Stack gap={2} width="83%" {...itemsStackProps}>
      {data.chckedItems.map((item, index) => (
        <CheckedItem
          key={index}
          title={item.title}
          sxTitle={item.sx}
          hideCheck={item.hideCheck || false}
        />
      ))}
    </Stack>
    {data.footerText && (
      <Typography variant="publicBody5Light" maxWidth="95%" color="primaryDark.900">
        {data.footerText}
      </Typography>
    )}
    <CustomButton
      sx={{ mt: 5 }}
      // href={process.env.REACT_APP_CALENDLY_URL as string}
      {...buttonProps}
    >
      {buttonProps?.text || `try for free`}
    </CustomButton>
  </Stack>
);

export default PlanDetails;
