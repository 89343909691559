import { Box, Stack, Typography, Divider, Paper } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CreateProjectDto, ProjectDto } from 'tdc-web-backend/projects/schemas';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { useEffect, useState } from 'react';
import LoadingButton from '../../../../../components/button/LoadingButton';
import ControlledTextInput from '../../../../../components/custom-inputs/ControlledTextInput';
import ControlledReferenceSelectInput from '../../../../../components/custom-inputs/ControlledReferenceSelectInput';
import useGetMany from '../../../../../utils/hooks/crud-hooks/useGetMany';
import useCreate from '../../../../../utils/hooks/crud-hooks/useCreate';
import CustomInput from '../../../../../components/custom-input/CustomInput';
import ConfirmModal from '../../../../../components/confirm-modal/ConfirmModal';
import { secondaryPink } from '../../../../../utils/color';
import { ReactComponent as ErrorAlertIcon } from '../../../../../assets/icons/layout-icons/ErrorAlertIcon.svg';
import { AxiosError } from 'axios';

const AdminCreateProject = () => {
  const navigate = useNavigate();
  const formMethods = useForm<CreateProjectDto>();
  const [isErrModalOpen, setIsErrModalOpen] = useState(false);
  const [errModalMsg, setErrModalMsg] = useState('');

  const formMethodsCheckBox = useForm({
    defaultValues: {
      requested_by_buyer: false,
      requested_by_seller: false,
    },
  });

  const { data: sellerData } = useGetMany<CompanyDto>({
    resource: '/companies?role=seller_tool&role=seller_service&status=approved',
  });

  const { data: buyerData } = useGetMany<CompanyDto>({
    resource: '/companies?role=buyer&status=approved',
  });

  const buyerChoices = buyerData?.data.results.map((buyer) => ({
    ...buyer,
    dName: buyer.name + (buyer.subscriptionPlan ? ` (${buyer.subscriptionPlan})` : ''),
  }));

  const sellerChoices = sellerData?.data.results.map((seller) => ({
    ...seller,
    dName: seller.name + (seller.subscriptionPlan ? ` (${seller.subscriptionPlan})` : ''),
  }));

  const { mutate, isLoading } = useCreate<ProjectDto, CreateProjectDto>({ resource: '/projects' });

  const { handleSubmit } = formMethods;

  const submitForm = (data: CreateProjectDto) => {
    console.log(data);

    if (formMethodsCheckBox.getValues('requested_by_buyer')) {
      data.requestedBy = data.buyer;
    } else if (formMethodsCheckBox.getValues('requested_by_seller')) {
      data.requestedBy = data.seller;
    }
    mutate(data, {
      onSuccess: (data) => {
        navigate('../projects');
      },
      onError: (err) => {
        setErrModalMsg((err as AxiosError).response?.data.message || 'Something went wrong');
        setIsErrModalOpen(true);
      },
    });
  };

  useEffect(() => {
    const subscription = formMethodsCheckBox.watch((value, e) => {
      if (e.type === 'change' && e.name === 'requested_by_buyer' && value.requested_by_buyer) {
        formMethodsCheckBox.setValue('requested_by_seller', false);
      }
      if (e.type === 'change' && e.name === 'requested_by_seller' && value.requested_by_seller) {
        formMethodsCheckBox.setValue('requested_by_buyer', false);
      }
    });
    return () => subscription.unsubscribe();
  }, [formMethodsCheckBox.watch]);

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(submitForm)}>
          <Stack spacing={2} direction="row" sx={{ py: 3 }}>
            <Stack spacing={3} width="100%">
              <Typography variant="h3">Create Project</Typography>
              <Stack spacing={3}>
                <Box>
                  <Divider />
                </Box>
                <Paper
                  sx={{
                    p: 3,
                    bgcolor: 'grey.100',
                  }}
                >
                  <Stack spacing={1}>
                    <Stack spacing={6}>
                      <Stack spacing={1} sx={{ px: 5 }}>
                        <Box sx={{ mb: 0 }}>
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            Title
                          </Typography>
                          <Typography variant="caption">
                            The title should include the necessery keywords and be between 4-9 words
                            long.
                          </Typography>
                        </Box>
                        <ControlledTextInput name="name" placeholder="Title" required />
                      </Stack>
                      <Stack spacing={1} sx={{ px: 5 }}>
                        <Box sx={{ mb: 0 }}>
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            Buyer
                          </Typography>
                        </Box>
                        <Stack direction="row" spacing={5} width="100%" alignItems="center">
                          <ControlledReferenceSelectInput
                            name="buyer"
                            choices={buyerChoices}
                            displayName="dName"
                            displayValue="id"
                            required
                            sx={{ width: '20rem' }}
                          />
                          <FormProvider {...formMethodsCheckBox}>
                            <CustomInput
                              input={<CustomInput.Checkbox />}
                              type="text"
                              name="requested_by_buyer"
                              label="Buyer requested the project"
                              disabled={isLoading}
                            />
                          </FormProvider>
                        </Stack>
                      </Stack>
                      <Stack spacing={1} sx={{ px: 5 }}>
                        <Box sx={{ mb: 0 }}>
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            Seller
                          </Typography>
                        </Box>
                        <Stack direction="row" spacing={5} width="100%" alignItems="center">
                          <ControlledReferenceSelectInput
                            name="seller"
                            choices={sellerChoices}
                            displayName="dName"
                            displayValue="id"
                            required
                            sx={{ width: '20rem' }}
                          />

                          <FormProvider {...formMethodsCheckBox}>
                            <CustomInput
                              input={<CustomInput.Checkbox />}
                              type="text"
                              name="requested_by_seller"
                              label="Seller requested the project"
                              disabled={isLoading}
                            />
                          </FormProvider>
                        </Stack>
                      </Stack>
                      <Stack spacing={1} sx={{ px: 5 }}>
                        <Box sx={{ mb: 0 }}>
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            Description
                          </Typography>
                        </Box>
                        <ControlledTextInput name="description" multiline minRows={4} required />
                      </Stack>
                    </Stack>
                  </Stack>
                </Paper>

                <Divider sx={{ pt: 5 }} />

                <LoadingButton
                  onButtonClick={() => null}
                  sx={{ alignSelf: 'end' }}
                  variant="contained"
                  size="medium"
                  fontWeight="bold"
                  minWidth={102}
                  type="submit"
                  loading={isLoading}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
      <ConfirmModal
        isModalOpen={isErrModalOpen}
        setIsModalOpen={setIsErrModalOpen}
        title="Error"
        message={
          <Typography color="primaryDark.500" lineHeight="21px">
            {errModalMsg}
          </Typography>
        }
        confirmButtonText="Close"
        // onConfirm={() => navigate('../project-requests')}
        iconProps={{
          icon: <ErrorAlertIcon fill={secondaryPink[700]} style={{ width: 24, height: 24 }} />,
          wrapperBackgroundColor: secondaryPink[100],
        }}
        showCancelButton={false}
      />
    </>
  );
};

export default AdminCreateProject;
