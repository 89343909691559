import { Box, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { mapIndustry, mapService, mapCurrency } from 'tdc-web-backend/enums/enums';
import ControlledTextInput from '../../../../components/custom-inputs/ControlledTextInput';
import ControlledContractNumberInput from '../../../../components/custom-inputs/ControlledContractNumberInput';
import { parseIntlNumber } from '../../../../utils/helpers';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';
import ControlledAutocompleteInput from '../../../../components/custom-inputs/ControlledAutocompleteInput';
import CustomInput from '../../../../components/custom-input/CustomInput';
import ControlledSelectInput from '../../../../components/custom-inputs/ControlledSelectInput';

const ScopeOfWorkForm = ({ disabled = false }: { disabled?: boolean }) => {
  const { getValues, setValue } = useFormContext();

  function handleBudgetDifferenceChange(newBudget: number) {
    const milestones = getValues('body.milestones');

    const milestonesBudgets = milestones.map((m: MilestoneDto) => m.budget);

    const oldBudget = milestonesBudgets.reduce((a: number, b: number) => a + b);

    if (newBudget < oldBudget) {
      const percentages = milestones.map((milestone: MilestoneDto) => milestone.budget / oldBudget);

      milestones.forEach((milestone: MilestoneDto, i: number) => {
        if (Number.isNaN(percentages[i])) {
          milestone.budget = 0;
        } else {
          milestone.budget = Math.ceil(percentages[i] * newBudget);
        }
      });

      setValue('body.milestones', milestones);

      // This actualy does nothing except passes the same value just
      // so that slider would refresh its state and move to  right position
      milestones.forEach((milestone: MilestoneDto, i: number) => {
        setValue(`body.milestones.${i}`, milestone);
      });
    }
  }
  return (
    <Stack spacing={4} sx={{ mt: 4 }}>
      <Stack spacing={2} direction="row">
        <Stack spacing={1} width="50%">
          <ControlledAutocompleteInput
            name="body.scope.services"
            label="Services provided"
            required
            multiple
            withChips
            choices={mapService}
            disabled={disabled}
          />
        </Stack>
        <Stack
          sx={{
            flex: 1,
            '.MuiBox-root': {
              flex: 1,
            },
          }}
          direction="row"
        >
          <ControlledAutocompleteInput
            name="body.scope.industry"
            label="Buyer industry"
            required
            choices={mapIndustry}
            fullWidth
            disabled={disabled}
            sx={{
              flex: 1,
            }}
          />
        </Stack>
      </Stack>

      <Stack spacing={1} mt={'0 !important'} direction="row" alignItems="start">
        <Stack
          direction="column"
          width="50%"
          sx={{
            flex: 1,
          }}
        >
          <BaseInputLabel required text="Project budget" />
          <Stack direction="row">
            <Stack
              sx={{
                flex: 1,
              }}
            >
              <ControlledSelectInput
                disabled
                name="body.scope.currency"
                choices={mapCurrency}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRight: 'none !important',
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                }}
              />
            </Stack>
            <Stack
              sx={{
                flex: 4,
              }}
            >
              <ControlledContractNumberInput
                disabled={disabled}
                initialValue={getValues('initialAmount')}
                name="dummyInitialAmount" // Value visible in the field, the actual initialAmount value is updated on blur
                placeholder="Project price"
                onBlur={(value) => {
                  let newValue = parseIntlNumber(value.toString());
                  if (Number.isNaN(newValue)) {
                    newValue = 0;
                  }
                  setValue('initialAmount', newValue);
                  handleBudgetDifferenceChange(newValue);
                }}
                min={0}
                fullWidth
                sx={{
                  flex: 1,
                  '.MuiOutlinedInput-root': {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  },
                }}
                required
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Box>
        <ControlledTextInput
          disabled={disabled}
          label="Project description"
          name="body.scope.description"
          multiline
          minRows={5}
          placeholder="Type"
          required
          variant="outlined"
        />
      </Box>
    </Stack>
  );
};

export default ScopeOfWorkForm;
