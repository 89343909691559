import { Divider, Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Title from './components/Title';
import Participant from './components/Participant';
import CustomButton from '../../../components/button/CustomButton';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/icons/layout-icons/ArrowLeftShortFill.svg';
import { ReactComponent as ClipboardCheckIcon } from '../../../assets/icons/layout-icons/ClipboardCheckIcon.svg';
import { primaryDark, secondaryBlue, secondaryPink } from '../../../utils/color';
import Actions from './components/Actions';
import useCreate from '../../../utils/hooks/crud-hooks/useCreate';
import Description from './components/Description';
import ConfirmModal from '../../../components/confirm-modal/ConfirmModal';
import HelpText from './components/HelpTest';
import { ReactComponent as ErrorAlertIcon } from '../../../assets/icons/layout-icons/ErrorAlertIcon.svg';
import { AxiosError } from 'axios';

const cancelButton = {
  '.MuiButton-startIcon': {
    border: `1px solid ${primaryDark['300']}`,
    borderRadius: '50%',
    padding: '14px 12px',
  },
  margin: '0 0 40px 0',
  padding: '2px',
  justifyContent: 'flex-start',
  color: primaryDark['500'],
};

const initialState = {
  projectName: '',
  projectDescription: '',
  participantCompanyName: '',
  participantCompanyUrl: '',
  contactName: '',
  contactEmail: '',
  contactJobTite: '',
  contactPhone: '',
};

const ProjectRequest = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrModalOpen, setIsErrModalOpen] = useState(false);
  const [errModalMsg, setErrModalMsg] = useState('');
  const navigate = useNavigate();
  const form = useForm({
    defaultValues: {
      ...initialState,
    },
  });

  const { mutate, isLoading } = useCreate({
    resource: 'project-requests',
  });

  const onSubmit = (data: any) => {
    mutate(
      { ...data },
      {
        onSuccess: () => {
          setIsModalOpen(true);
        },
        onError: (err) => {
          setErrModalMsg((err as AxiosError).response?.data.message || 'Something went wrong');
          setIsErrModalOpen(true);
        },
      },
    );
  };

  return (
    <FormProvider {...form}>
      <CustomButton
        variant="primaryText"
        sx={cancelButton}
        startIcon={<ArrowLeftIcon fill={primaryDark['500']} />}
        onClick={() => {
          navigate('../');
        }}
        disabled={isLoading}
      >
        Cancel
      </CustomButton>

      <Stack
        direction="column"
        spacing={3}
        sx={{ position: 'relative', left: '-24px', maxWidth: '980px' }}
      >
        <HelpText />
        <Title isLoading={isLoading} />

        <Participant isLoading={isLoading} />
        <Divider />
        <Description isLoading={isLoading} />
        <Divider />
        <Actions onSubmit={onSubmit} isLoading={isLoading} />
      </Stack>
      <ConfirmModal
        width="600px"
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title="Project setup Confirmation"
        message={
          <Typography color="primaryDark.500" lineHeight="21px">
            Your first step in creating a new project has been successfully completed. <br />
            <br />
            Once the E2E project is created, you will be notified on your dashboard. <br />
            <br />
            The project status will be marked as 'pending,' and you will then be able to sign the
            digital contract to begin the project.
          </Typography>
        }
        confirmButtonText="Close"
        onConfirm={() => {
          setIsModalOpen(false);
          navigate('../');
        }}
        iconProps={{
          icon: <ClipboardCheckIcon fill={secondaryBlue[700]} style={{ width: 24, height: 24 }} />,
          wrapperBackgroundColor: secondaryBlue[100],
        }}
        showCancelButton={false}
      />
      <ConfirmModal
        isModalOpen={isErrModalOpen}
        setIsModalOpen={setIsErrModalOpen}
        title="Error"
        message={
          <Typography color="primaryDark.500" lineHeight="21px">
            {errModalMsg}
          </Typography>
        }
        onConfirm={() => navigate('..')}
        confirmButtonText="Close"
        iconProps={{
          icon: <ErrorAlertIcon fill={secondaryPink[700]} style={{ width: 24, height: 24 }} />,
          wrapperBackgroundColor: secondaryPink[100],
        }}
        showCancelButton={false}
      />
    </FormProvider>
  );
};

export default ProjectRequest;
