import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { MembershipRole } from 'tdc-web-backend/enums/enums';
import LinkTabs, { LinkTabsItem } from '../../../components/link-tabs/LinkTabs';
import useAuth from '../../../utils/hooks/useAuth';

const tabs: { tab: LinkTabsItem; hasAccess: MembershipRole[] }[] = [
  {
    tab: { value: 'account', label: 'Account', to: 'account' },
    hasAccess: [MembershipRole.Owner, MembershipRole.Admin, MembershipRole.Editor],
  },
  {
    tab: { value: 'billing', label: 'Billing & Payments', to: 'billing' },
    hasAccess: [MembershipRole.Owner, MembershipRole.Admin],
  },
  {
    tab: { value: 'members', label: 'Members & Permissions', to: 'members' },
    hasAccess: [MembershipRole.Owner, MembershipRole.Admin],
  },
  {
    tab: { value: 'security', label: 'Security', to: 'security' },
    hasAccess: [MembershipRole.Owner, MembershipRole.Admin, MembershipRole.Editor],
  },
];

const Settings = () => {
  const {
    authData: { userData },
  } = useAuth();

  const filteredTabs: LinkTabsItem[] = tabs
    .filter((tab) => tab.hasAccess.includes(userData?.membership?.role as MembershipRole))
    .map((tab) => tab.tab);

  return (
    <Stack spacing={5}>
      <LinkTabs tabs={filteredTabs} />
      <Outlet />
    </Stack>
  );
};

export default Settings;
