import { Box, Stack, Typography } from '@mui/material';
import { PUBLIC_APPBAR_HEIGHT } from '../../../../layout/public-layout/navigation/Navigation';
import CustomButton from '../../../../components/button/CustomButton';
import EbookImg from '../../../../assets/img/public-pages/by_mastering-outsourcing.png';
import CheckedItem from '../../Pricing/components/PlanDetails/components/CheckedItem';

const B2bInfographic = () => (
  <Stack
    spacing={12.5}
    direction="column"
    alignItems="center"
    sx={{
      marginTop: `${PUBLIC_APPBAR_HEIGHT + 80}px`,
      marginBottom: { xs: '10rem' },
      overflow: 'hidden',
      mx: 'auto',
      maxWidth: '1440px',
      width: '100%',
      px: { xs: '16px', md: '32px', gr: '80px' },
    }}
  >
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ px: '138px' }}
      gap={0}
    >
      <Typography variant="publicBody3Regular" color="primaryLight.500" sx={{ mb: 1 }}>
        Free Download
      </Typography>
      <Typography
        variant="publicHeading2"
        textAlign="center"
        sx={{ mb: 3, fontSize: { xs: '3rem', sm: '4rem' } }}
      >
        Maximize your business growth by mastering outsourcing!
      </Typography>
      <Typography variant="publicBody2Regular" textAlign="center" sx={{ mb: 5 }}>
        Unlock the full potential of your business by leveraging the power of outsourcing! In
        today’s fast-paced world, outsourcing is not just a cost-saving measure; it’s a strategic
        move that can help you scale efficiently, access top talent, and focus on your core business
        activities.
      </Typography>
      <CustomButton
        variant="primary"
        sx={{
          py: { xs: '1.125rem' },
          width: 'fit-content',
        }}
        href="https://tdc-production.ams3.cdn.digitaloceanspaces.com/_public/ebooks/spona_b2b_infographic.pdf"
      >
        Download for free
      </CustomButton>
    </Stack>

    <Stack
      direction={{ xs: 'column', bg: 'row' }}
      alignItems="center"
      spacing={10}
      justifyContent="space-between"
      sx={{ width: '100%' }}
      overflow="hidden"
    >
      <Box
        component="img"
        src={EbookImg}
        alt="Embrasing AI e-book cover"
        sx={{
          maxWidth: { xs: '471px', sm: '628px' }, //470 628
          maxHeight: { xs: '425px', sm: '566px' }, //425px 566
          borderRadius: '12px',
        }}
      />
      <Stack direction="column" gap={2} sx={{ mt: { xs: '4rem', bg: 0 } }}>
        <Typography variant="publicHeading4" sx={{ mb: '1rem' }}>
          You will find out:
        </Typography>
        <CheckedItem title="The impact of outsourcing in international market" />
        <CheckedItem title="Global insights on marketing outsourcing across different countries" />
        <CheckedItem title="Top 3 marketing trends in 2024" />
      </Stack>
    </Stack>
  </Stack>
);

export default B2bInfographic;
